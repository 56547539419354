<template>
  <section id="mapbox-container">
    <p v-if="initializationError"
      class="text-align-center fs-1-6rem mt-3">
      {{ $t('map.initialization-error', {message: initializationError.message}) }}
    </p>
    <template v-else>
      <cancel-service v-if="showCancelPanel"
        :visible.sync="showCancelPanel"
        :service-uid="cancelUid" />

      <div id="mapbox" />
      <canvas id="overlay" />
      <div id="controls"
        v-loading="pendingPostLoadNavigation"
        :element-loading-text="$t('general.loading')">
        <div class="top-left">
          <div>
            <!-- Filtering Locations -->
            <div class="control-group mb-1">
              <div>
                <el-tooltip :content="$t('map.all-locations')"
                  placement="right">
                  <el-button class="top-button"
                    @click="setLocationsFilterType('all')">
                    <div :class="{ selected: locationsFilter.type === 'all'}">
                      <img src="/map-images/empty-location-pin.svg"
                        alt="All locations"
                        class="controls-icon">
                    </div>
                  </el-button>
                </el-tooltip>
              </div>
              <div>
                <el-tooltip :content="$t('map.occupied-locations')"
                  placement="right">
                  <el-button class="middle-button"
                    @click="setLocationsFilterType('none')">
                    <div :class="{ selected: locationsFilter.type === 'none'}">
                      <img src="/map-images/inactive-empty-location-pin.svg"
                        alt="All locations"
                        class="controls-icon">
                    </div>
                  </el-button>
                </el-tooltip>
              </div>
              <div>
                <el-popover placement="right-start"
                  trigger="click"
                  :append-to-body="false">
                  <div class="mb-1">
                    <el-checkbox v-model="locationsFilter.hundredPorts">
                      {{ $t('map.thing-available', { thing: `${$t('general.speed-gbps', {speed: 100})} ${$t('productNames.ports')}`}) }}
                    </el-checkbox>
                    <el-checkbox v-model="locationsFilter.mcr">
                      {{ $t('map.thing-available', { thing: $t('productNames.mcrs') }) }}
                    </el-checkbox>
                    <el-checkbox v-model="locationsFilter.mve">
                      {{ $t('map.thing-available', { thing: $t('productNames.mves') }) }}
                    </el-checkbox>
                  </div>
                  <el-form :inline="true">
                    <el-form-item :label="$t('map.dc-provider')">
                      <el-select v-model="locationsFilter.dcName"
                        size="small"
                        filterable
                        clearable
                        :placeholder="$t('map.dc-provider')"
                        :popper-append-to-body="false">
                        <el-option v-for="option in dcNames"
                          :key="option"
                          :value="option" />
                      </el-select>
                    </el-form-item>
                  </el-form>
                  <template #reference>
                    <el-tooltip :content="$t('map.filtered-locations')"
                      placement="right">
                      <el-button class="bottom-button"
                        @click="setLocationsFilterType('filtered')">
                        <div :class="{ selected: locationsFilter.type === 'filtered'}">
                          <i class="fas fa-filter" />
                        </div>
                      </el-button>
                    </el-tooltip>
                  </template>
                </el-popover>
              </div>
            </div>

            <!-- Map Key -->
            <div class="control-group">
              <div>
                <el-popover placement="right-start"
                  trigger="click"
                  width="600"
                  :append-to-body="false">
                  <table>
                    <thead>
                      <tr>
                        <th colspan="2">
                          {{ $t('map.key') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in mapKeyItems"
                        :key="index">
                        <td class="key">
                          <img :src="item.image"
                            :alt="item.alt"
                            class="key-image">
                        </td>
                        <td class="value">
                          {{ $t(item.translationKey) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <template #reference>
                    <el-tooltip :content="$t('map.key')"
                      placement="right">
                      <el-button class="top-button">
                        <i class="fas fa-key" />
                      </el-button>
                    </el-tooltip>
                  </template>
                </el-popover>
              </div>
              <!-- Help -->
              <div>
                <el-popover placement="right-start"
                  trigger="click"
                  :append-to-body="false">
                  <table>
                    <thead>
                      <tr>
                        <th>{{ $t('map.help-description') }}</th>
                        <th class="pl-2">
                          {{ $t('map.help-action') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ $t('map.help-items.zoom-in-title') }}</td>
                        <td>
                          <ul>
                            <li>{{ $t('map.help-items.zoom-in-action-1') }}</li>
                            <li>{{ $t('map.help-items.zoom-in-action-2') }}</li>
                            <li>{{ $t('map.help-items.zoom-in-action-3') }}</li>
                            <li>{{ $t('map.help-items.zoom-in-action-4') }}</li>
                            <li>{{ $t('map.help-items.zoom-in-action-5') }}</li>
                            <li>{{ $t('map.help-items.zoom-in-action-6') }}</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t('map.help-items.double-zoom-in-title') }}</td>
                        <td>
                          <ul>
                            <li>{{ $t('map.help-items.double-zoom-in-action-1') }}</li>
                            <li>{{ $t('map.help-items.double-zoom-in-action-2') }}</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t('map.help-items.zoom-out-title') }}</td>
                        <td>
                          <ul>
                            <li>{{ $t('map.help-items.zoom-out-action-1') }}</li>
                            <li>{{ $t('map.help-items.zoom-out-action-2') }}</li>
                            <li>{{ $t('map.help-items.zoom-out-action-3') }}</li>
                            <li>{{ $t('map.help-items.zoom-out-action-4') }}</li>
                            <li>{{ $t('map.help-items.zoom-out-action-5') }}</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t('map.help-items.double-zoom-out-title') }}</td>
                        <td>
                          <ul>
                            <li>{{ $t('map.help-items.double-zoom-out-action-1') }}</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t('map.help-items.area-zoom-in-title') }}</td>
                        <td>
                          <ul>
                            <li>{{ $t('map.help-items.area-zoom-in-action-1') }}</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t('map.help-items.pan-title') }}</td>
                        <td>
                          <ul>
                            <li>{{ $t('map.help-items.pan-action-1') }}</li>
                            <li>{{ $t('map.help-items.pan-action-2') }}</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t('map.help-items.orientation-title') }}</td>
                        <td>
                          <ul>
                            <li>{{ $t('map.help-items.orientation-action-1') }}</li>
                            <li>{{ $t('map.help-items.orientation-action-2') }}</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <template #reference>
                    <el-tooltip :content="$t('map.help')"
                      placement="right">
                      <el-button class="bottom-button">
                        <i class="fas fa-question-circle" />
                      </el-button>
                    </el-tooltip>
                  </template>
                </el-popover>
              </div>
            </div>
          </div>
          <div v-if="locationsFilter.type === 'filtered'">
            <div class="filtered-message">
              {{ $t('map.locations-filtered') }}
            </div>
          </div>
        </div>

        <div class="top-right">
          <div>
            <div class="flex-row-centered capture-events control-group geocoder-group">
              <div id="geocoder" />
              <el-tooltip placement="bottom">
                <template #content>
                  <div v-html="$t('map.search-tips')" /> <!-- eslint-disable-line vue/no-v-html -->
                </template>

                <i class="fas fa-question-circle color-info popover-info-icon" />
              </el-tooltip>
            </div>
          </div>

          <div class="ml-0-5 capture-events">
            <!-- Size buttons -->
            <div class="control-group mb-1">
              <div>
                <el-tooltip :content="$t('map.fullscreen')"
                  placement="left">
                  <el-button class="top-button"
                    :disabled="!supportsFullscreen"
                    @click="setDisplaySize('fullscreen', true, false)">
                    <div :class="{ selected: mapSize === 'fullscreen'}">
                      <i class="fas fa-expand" />
                    </div>
                  </el-button>
                </el-tooltip>
              </div>
              <div>
                <el-tooltip :content="$t('map.big')"
                  placement="left">
                  <el-button class="middle-button"
                    @click="setDisplaySize('big', true, false)">
                    <div :class="{ selected: mapSize === 'big'}">
                      <i class="fa-sharp fa-square" />
                    </div>
                  </el-button>
                </el-tooltip>
              </div>
              <div>
                <el-tooltip :content="$t('map.small')"
                  placement="left">
                  <el-button class="bottom-button"
                    @click="setDisplaySize('small', true, false)">
                    <div :class="{ selected: mapSize === 'small'}">
                      <i class="fas fa-compress" />
                    </div>
                  </el-button>
                </el-tooltip>
              </div>
            </div>

            <!-- Projection buttons -->
            <div class="control-group mb-1">
              <div>
                <el-tooltip :content="$t('map.globe')"
                  placement="left">
                  <el-button class="top-button"
                    @click="setProjection('globe')">
                    <div :class="{ selected: projection === 'globe'}">
                      <i class="fas fa-earth-americas" />
                    </div>
                  </el-button>
                </el-tooltip>
              </div>
              <div>
                <el-tooltip :content="$t('map.mercator')"
                  placement="left">
                  <el-button class="bottom-button"
                    @click="setProjection('mercator')">
                    <div :class="{ selected: projection === 'mercator'}">
                      <img src="/map-images/mercator.svg"
                        alt="Mercator projection"
                        class="controls-icon">
                    </div>
                  </el-button>
                </el-tooltip>
              </div>
            </div>

            <!-- Zoom Buttons -->
            <div class="control-group mb-1">
              <div>
                <el-tooltip :content="$t('map.zoom-in-lots')"
                  placement="left">
                  <el-button class="top-button"
                    :disabled="zoom === MAPBOX_MAX_ZOOM"
                    @click="zoomIn(true)">
                    <div class="stacked">
                      <i class="fas fa-plus" />
                      <i class="fas fa-plus" />
                    </div>
                  </el-button>
                </el-tooltip>
              </div>
              <div>
                <el-tooltip :content="$t('map.zoom-in')"
                  placement="left">
                  <el-button class="middle-button"
                    :disabled="zoom === MAPBOX_MAX_ZOOM"
                    @click="zoomIn(false)">
                    <i class="fas fa-plus" />
                  </el-button>
                </el-tooltip>
              </div>
              <div>
                <el-tooltip :content="$t('map.zoom-out')"
                  placement="left">
                  <el-button class="middle-button"
                    :disabled="zoom === MAPBOX_MIN_ZOOM"
                    @click="zoomOut(false)">
                    <i class="fas fa-minus" />
                  </el-button>
                </el-tooltip>
              </div>
              <div>
                <el-tooltip :content="$t('map.zoom-out-lots')"
                  placement="left">
                  <el-button class="bottom-button"
                    :disabled="zoom === MAPBOX_MIN_ZOOM"
                    @click="zoomOut(true)">
                    <div class="stacked">
                      <i class="fas fa-minus" />
                      <i class="fas fa-minus" />
                    </div>
                  </el-button>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
/**
 *
- Assess the filtering options in MPOne and see if/how they can be incorporated into the current map solution or added as a separate option (could be done after initial release)
- Find out what latency information is available to be displayed and work out how/where to display that information (could be done after initial release)
- Review the implemented features with others and see if there are any things that should be improved before initial release
- Refactor code to appropriate modules (could be done after initial release, but I would prefer to do it before)
 *
 */


import Vue from 'vue'
import mapboxGl from 'mapbox-gl'
import MapboxLanguage from '@mapbox/mapbox-gl-language'
import { mapState, mapGetters, mapMutations } from 'vuex'
import * as turf from '@turf/turf'
import MapboxglSpiderifier from 'mapboxgl-spiderifier'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import { MuMegaIcon } from '@megaport/mega-ui'
import { escape, debounce } from 'lodash'
import stableStringify from 'fast-json-stable-stringify'
import Supercluster from 'supercluster'

import LocationPopup from '@/components/map-support/LocationPopup.vue'
import ConnectionsPopup from '@/components/map-support/ConnectionsPopup.vue'
import IXPopup from '@/components/map-support/IxPopup.vue'
import PortLikePopup from '@/components/map-support/PortLikePopup.vue'
import PartnerPortPopup from '@/components/map-support/PartnerPortPopup.vue'

import CancelService from '@/components/cancel/Cancel.vue'

import { findKnownFeatures } from '@/components/map-support/geocoder-support'
import { getIXLocationData } from '@/components/map-support/ix-location'
import {
  UP_COLOR_START,
  UP_COLOR_END,
  DOWN_COLOR_START,
  DOWN_COLOR_END,
  UNKNOWN_COLOR_START,
  UNKNOWN_COLOR_END,
  THIRD_PARTY_COLOR,
} from '@/Globals'
import { capitalizeFirstOnly, convertSpeed } from '@/helpers'
import providers from '@/providers'
import mapKeyItems from '@/components/map-support/map-key.json'
import {
  normaliseLineCoordinates,
  splitCoordinates,
  midpointAcrossMeridian,
  bboxAcrossMeridian,
  findPortOrPartnerInfo,
} from '@/components/map-support/map-utilities'
import { createClusterMarkerSvg } from '@/utils/UpDownUnknown.js'
import {
  setMapSize,
  getMapSize,
  getFocussedUid,
  setFocussedUid,
  setMapDisplay,
  setLocationsFilter,
  getLocationsFilter,
  locationsMatchingFilter,
  getMapDisplay,
  setServicesPage,
} from '@/utils/MapDataUtils.js'
import { interpolateColors } from '@/utils/ColorUtils.js'
import { captureEvent } from '@/utils/analyticUtils'

const MEGAPORT_HEAD_OFFICE = [153.03775, -27.45606]
const MAPBOX_CLUSTER_RADIUS = 40
const SPIDER_MARKER_SIZE = 36

const EPSILON = 0.001 // For floating point number comparisons

// These settings are based on where the arrow on the popup is
const BASE_MARKER_POPUP_OFFSET = {
  top: [0, 0],
  'top-left': [20, -32],
  'top-right': [-20, -32],
  bottom: [0, -50],
  'bottom-left': [20, -32],
  'bottom-right': [-20, -32],
  left: [20, -32],
  right: [-20, -32],
}

const CENTER_LINE_MARKER_SIZE = 40

const STATUS_UNKNOWN = 1
const STATUS_DOWN = 2
const STATUS_UP = 3

// For drawing multiple lines
const LINE_WIDTH = 2
const GAP_WIDTH = 3

const FEATURE_PARTNER_PORT = 'PARTNER_PORT'

export default {
  components: {
    CancelService,
  },

  inject: ['disabledFeatures'],

  data() {
    return {
      initializationError: null,
      map: null,
      mapboxlanguage: null,
      resizeObserver: null,
      rockets: [],
      animateRocket: false,
      edgeOfWorldOffset: 0,
      hoverPopup: null,
      spiderifier: null,
      geocoder: null,
      images: {},
      zoom: 10,
      // Limit the zoom levels to something sensible - put them in the data so as to reference in template
      MAPBOX_MAX_ZOOM: 20,
      MAPBOX_MIN_ZOOM: 1,
      clusterMarkers: [],
      hiddenOccupiedLocationId: null,
      showingSpiderDetail: false,
      pendingPostLoadNavigation: false,
      searchResultMarker: null,
      showCancelPanel: false,
      cancelUid: null,
      showingPopup: false,
      mapKeyItems,
      destroyed: false,
      mapSize: undefined,
      projection: undefined,
      locationsFilter: {
        type: 'all',
        hundredPorts: false,
        mcr: false,
        mve: false,
        dcName: '',
      },
      emptyLocationRefreshKey: 0,
      lastConnectionsSourceData: '',
      dependentDataReady: false,
    }
  },

  computed: {
    ...mapState(['language']),
    ...mapState('Services', ['locations', 'servicesReady', 'locationsReady']),
    ...mapState('Services', ['freshLogin']),

    // Use the filtered locations so that it only shows the ones available for a vantage managed account
    ...mapGetters('Services', ['filteredLocations', 'allMyServices', 'myPorts', 'myConnections', 'findPort']),
    ...mapGetters('Company', ['companyUid']),
    ...mapGetters('ApplicationContext', ['companyContextLoading']),
    usedLocationIds() {
      const usedLocationIds = new Set(this.myPorts.map(port => port.locationId))
      for (const connection of this.myConnections) {
        if (connection.productType === this.G_PRODUCT_TYPE_VXC) {
          usedLocationIds.add(connection.aEnd.locationId)
          usedLocationIds.add(connection.bEnd.locationId)
        }
      }
      return usedLocationIds
    },
    emptyLocationSourceData() {
      const mappedFeatures = this.applicableLocations.map(this.locationMapper)
      return {
        type: 'FeatureCollection',
        features: mappedFeatures.filter(feature => !this.usedLocationIds.has(feature.properties.locationId)),
      }
    },
    dcNames() {
      const nameSet = new Set()
      for (const location of this.applicableLocations) {
        nameSet.add(location.dc.name)
      }
      return [...nameSet].sort((a, b) => {
        return a.localeCompare(b, undefined, { sensitivity: 'base' })
      })
    },
    applicableLocations() {
      this.emptyLocationRefreshKey // Include this to force recalculation
      // Start with filteredlocations so that we do not get any that are not applcable for managed companies
      const applicableLocations = locationsMatchingFilter(this.filteredLocations)
      // Make sure to only include locations that are active or in deployment status
      return applicableLocations.filter(location => ['Deployment', 'Active'].includes(location.status))
    },
    locationFeatures() {
      return this.filteredLocations.filter(location => ['Deployment', 'Active'].includes(location.status)).map(this.locationMapper)
    },
    supportsFullscreen() {
      return document.fullscreenEnabled
    },
    emptyLocationsVisible() {
      return ['all', 'filtered'].includes(this.locationsFilter.type)
    },
  },

  watch: {
    language(newValue) {
      this.languageChanged(newValue)
    },
    filteredLocations() {
      this.reloadDependentData()
    },
    allMyServices(newVal) {
      this.spiderifier?.each(leg => {
        // Need to handle both ports and third party ports that may not need to be there now the connection
        // that made it required is gone
        if (leg.feature.type === this.G_PRODUCT_TYPE_MEGAPORT) {
          if (!newVal.some(service => service.productUid === leg.feature.data.productUid)) {
            this.spiderifier.unspiderfy()
            this.showingSpiderDetail = false
          }
        } else if (leg.feature.type === FEATURE_PARTNER_PORT) {
          if (!leg.feature.data.associatedVxcs || leg.feature.data.associatedVxcs.length === 0) {
            this.spiderifier.unspiderfy()
            this.showingSpiderDetail = false
          }
        }
      })
      // If the services change, we need to update all the sources that could be affected
      this.reloadDependentData()
    },
    servicesReady() {
      this.navigatePostLoad()
    },
    locationsReady() {
      this.navigatePostLoad()
    },
    locationFeatures() {
      this.navigatePostLoad()
    },
    dependentDataReady() {
      if (this.dependentDataReady) {
        this.navigatePostLoad()
      }
    },
    locationsFilter: {
      deep: true,
      handler() {
        setLocationsFilter(this.locationsFilter)
        this.reloadDependentData()
      },
    },
    async companyContextLoading(newValue) {
      if (newValue === false) {
        if (!this.map || !this.servicesReady || !this.locationsReady || this.locationFeatures.length === 0) {
          this.pendingPostLoadNavigation = true
        } else if (getMapDisplay().zoom) {
          const { zoom, longitude, latitude, bearing } = getMapDisplay()
          this.map.setCenter([longitude, latitude])
          this.map.setZoom(zoom)
          this.map.setBearing(bearing)
        } else {
          // Fit all services in to view
          const bounds = await this.calculateSettingsForAllServices()
          this.map.fitBounds(bounds, { padding: 200, animate: false })
        }
        setServicesPage('/dashboard')
      }
    },
  },

  mounted() {
    this.debouncedUpdateClusterMarkers = debounce(this.updateOccupiedClusterMarkers, 50)
    this.debouncedHandleMapRender = debounce(this.handleMapRendered, 50)
    this.debouncedMapContainerResized = debounce(this.mapContainerResized, 200)
    this.debouncedDependentDataChanged = debounce(this.dependentDataChanged, 50)
    mapboxGl.accessToken = this.$appConfiguration.mapbox.MAPBOX_TOKEN

    // Create a reusable hover popup, but don't add it to the map yet.
    this.hoverPopup = new mapboxGl.Popup({
      offset: BASE_MARKER_POPUP_OFFSET,
      closeButton: false,
      closeOnClick: false,
      className: 'hover-popup',
      maxWidth: '40%',
    })

    this.initMap()

    this.resizeObserver = new ResizeObserver(() => {
      this.debouncedMapContainerResized()
    })
    this.resizeObserver.observe(document.getElementById('mapbox-container'))
    this.resizeObserver.observe(document.body)
  },

  beforeDestroy() {
    this.destroyed = true
    this.closeAllPopups()
    this.removeSpider()
    const geocoderElement = document.getElementById('geocoder')
    if (geocoderElement) {
      geocoderElement.innerHTML = ''
    }
    this.resizeObserver?.disconnect()
  },

  methods: {
    ...mapMutations('Auth', ['setFreshLogin']),
    reloadDependentData() {
      this.dependentDataReady = false
      this.debouncedDependentDataChanged()
    },
    async setDisplaySize(mode, userEvent, bypassResize) {
      // Note that full screen mode can only be requested by a user event
      let appliedMode = mode
      if (mode !== 'fullscreen' && document.fullscreenElement) {
        document.exitFullscreen()
      }
      if (mode === 'fullscreen' && !document.fullscreenElement) {
        if (!this.supportsFullscreen || !userEvent) {
          appliedMode = 'big'
        } else {
          try {
            await document.getElementById('mapbox-container').requestFullscreen()
          } catch (error) {
            this.$notify({
              title: this.$t('map.fullscreen-error-title'),
              message: this.$t('map.fullscreen-error-message', { errorMessage: error.message, errorName: error.name }),
          })
            appliedMode = 'big'
          }
        }
      }
      this.mapSize = appliedMode
      setMapSize(appliedMode)
      if (!bypassResize) {
        this.debouncedMapContainerResized()
      }
      if (userEvent) {
        captureEvent(`dashboard.map.size-${appliedMode}.click`)
      }
    },
    setLocationsFilterType(filterType) {
      const affectedLayers = ['empty-locations-layer', 'empty-location-clusters-layer', 'empty-location-cluster-counts-layer']
      if (!this.map || affectedLayers.some(id => this.map.getLayer(id) === undefined)) {
        this.$notify({
          title: this.$t('map.not-ready-title'),
          message: this.$t('map.not-ready-message'),
        })
        return
      }
      const wasVisible = this.emptyLocationsVisible
      const willBeVisible = ['all', 'filtered'].includes(filterType)

      this.locationsFilter.type = filterType

      if (wasVisible !== willBeVisible) {
        for (const layer of affectedLayers) {
          this.map.setLayoutProperty(layer, 'visibility', willBeVisible ? 'visible' : 'none')
        }
      }

      captureEvent(`dashboard.map.show-${filterType}.click`)
    },
    /**
     * Set up the core map functionality and call all the methods to add our own stuff.
     */
    async initMap() {
      let mapSettings = {
        container: 'mapbox',
        style: this.$appConfiguration.mapbox.MAPBOX_STYLE_PRODUCTION,
        center: MEGAPORT_HEAD_OFFICE,
        zoom: 4,
        attributionControl: false,
        projection: 'globe',
        testMode: process.env.NODE_ENV === 'test',
        minZoom: this.MAPBOX_MIN_ZOOM,
        maxZoom: this.MAPBOX_MAX_ZOOM,
        maxPitch: 0,
        cooperativeGestures: true,
      }

      if (this.freshLogin) {
        setMapDisplay({})
        this.setFreshLogin(false)
      }

      this.locationsFilter = getLocationsFilter()

      if (getFocussedUid()) {
        // Work out the settings for this uid. Note that this will not work if the services and locations are not loaded yet
        if (!this.servicesReady || !this.locationsReady) {
          this.pendingPostLoadNavigation = true
        } else {
          const settings = await this.calculateSettingsForSelectedUid()
          mapSettings = {
            ...mapSettings,
            ...settings,
          }
        }
      } else {
        // Check if we have full coordinates, and if so, use them
        const { zoom, longitude, latitude, bearing, projection = 'globe' } = getMapDisplay()
        if (zoom) {
          mapSettings.center = [longitude, latitude]
          mapSettings.zoom = zoom
          mapSettings.bearing = bearing
          mapSettings.projection = projection
        } else if (!this.servicesReady || !this.locationsReady || this.locationFeatures.length === 0) {
          this.pendingPostLoadNavigation = true
        } else {
          const bounds = await this.calculateSettingsForAllServices()
          mapSettings.bounds = bounds
          mapSettings.fitBoundsOptions = { padding: 200 }
        }
      }

      this.projection = mapSettings.projection

      // Do this before the map is instantiated to ensure that the bounds works
      this.mapContainerResized()

      try {
        this.map = new mapboxGl.Map(mapSettings)
      } catch (error) {
        this.initializationError = error
        return
      }
      this.zoom = this.map.getZoom()

      if (getFocussedUid() && !this.pendingPostLoadNavigation) {
        // We should already have the map displaying the right thing - need to expand the spiderifier
        // and show the popup.
        this.showPopupForUid(getFocussedUid())
        // It has been processed, so cancel it out
        setFocussedUid(null)
      }

      this.createImages()
      this.createSpiderifier()
      this.addControls()
      // Do this again after the map is loaded to make sure everything else is in order
      this.debouncedMapContainerResized()
      this.addListeners()
    },
    async showPopupForUid(productUid) {
      const product = this.allMyServices.find(item => item.productUid === productUid) || findPortOrPartnerInfo(productUid)
      if ([this.G_PRODUCT_TYPE_MEGAPORT, this.G_PRODUCT_TYPE_MCR2, this.G_PRODUCT_TYPE_MVE].includes(product.productType) || !product.productType) {
        const location = this.locations.find(loc => loc.id === product.locationId)
        this.removeSpider()
        // Make sure it is not in a cluster
        const zoom = await this.zoomLevelForNonClusteredLocation(product.locationId)
        this.map.flyTo({
          center: [location.longitude, location.latitude],
          zoom,
          essential: true,
        })

        const listener = () => {
          // Note that this just returns features represented in the current tiles, but we have alredy flown to it by now
          const features = this.map.querySourceFeatures('occupied-locations-source')
          const feature = features.find(f => Math.abs(f.geometry.coordinates[0] - location.longitude) < EPSILON && Math.abs(f.geometry.coordinates[1] - location.latitude) < EPSILON)
          this.spiderifyFeature(feature)
          this.spiderifier.each(spiderLeg => {
            if (spiderLeg.feature.data) {
              const relevantUuids = [spiderLeg.feature.data.productUid]
              if (spiderLeg.feature.data.productType === this.G_PRODUCT_TYPE_MEGAPORT && spiderLeg.feature.data.aggregationId) {
                relevantUuids.push(...spiderLeg.feature.data._subLags.map(port => port.productUid))
              }
              if (relevantUuids.includes(productUid)) {
                const popupOffsets = MapboxglSpiderifier.popupOffsetForSpiderLeg(spiderLeg)
                this.fixSpiderOffsets(popupOffsets)
                this.showPortLikePopup(product, spiderLeg.mapboxMarker.getLngLat(), popupOffsets)
              }
            }
          })
          this.map.off('idle', listener)
        }
        this.map.on('idle', listener)
      } else if ([this.G_PRODUCT_TYPE_VXC, this.G_PRODUCT_TYPE_IX].includes(product.productType)) {
        this.closeAllPopups()
        const listener = async () => {
          const features = this.map.querySourceFeatures('connection-midpoints-source')
          for (const feature of features) {
            const connections = JSON.parse(feature.properties.connections)
            const connection = connections.find(c => c.productUid === productUid)
            let endPoints = undefined
            if (connection) {
              if (product.productType === this.G_PRODUCT_TYPE_IX) {
                const location = this.locations.find(loc => loc.id === product.locationId)
                const ixCoordinates = await getIXLocationData(product.networkServiceType)

                endPoints = [[location.longitude, location.latitude], ixCoordinates]
              } else {
                const aEndLocation = this.locations.find(loc => loc.id === product.aEnd.locationId)
                const bEndLocation = this.locations.find(loc => loc.id === product.bEnd.locationId)
                endPoints = [[aEndLocation.longitude, aEndLocation.latitude], [bEndLocation.longitude, bEndLocation.latitude]]
              }

              normaliseLineCoordinates(endPoints)
              const coordinates = midpointAcrossMeridian(...endPoints)
              const start = JSON.parse(feature.properties.start)
              const end = JSON.parse(feature.properties.end)

              this.showConnectionsPopup(connections, start, end, coordinates, productUid)
              break
            }
          }
          this.map.off('idle', listener)
        }
        this.map.on('idle', listener)
      }
    },
    async calculateSettingsForAllServices() {
      if (this.allMyServices.length === 0) {
        // Try to work out the user's location using IP rather than Geolocation so we don't need to ask permission
        let point = undefined
        try {
          const response = await window.fetch('https://ipapi.co/json')
          const json = await response.json()
          if (json.latitude && json.longitude) {
            point = turf.point([json.longitude, json.latitude])
          } else {
            point = turf.point(MEGAPORT_HEAD_OFFICE)
          }
        } catch (error) {
          point = turf.point(MEGAPORT_HEAD_OFFICE)
        }
        const buffered = turf.buffer(point, 50, { units: 'kilometers' })
        return turf.bbox(buffered)
      }
      const sourceData = await this.occupiedLocationSourceData()
      const bbox = turf.bbox(sourceData)
      return bbox
    },
    async calculateSettingsForSelectedUid(overrideUid) {
      const destinationUid = overrideUid || getFocussedUid()
      const product = this.allMyServices.find(item => item.productUid === destinationUid) || findPortOrPartnerInfo(destinationUid)
      if ([this.G_PRODUCT_TYPE_MEGAPORT, this.G_PRODUCT_TYPE_MCR2, this.G_PRODUCT_TYPE_MVE].includes(product.productType) || !product.productType) {
        const location = this.locations.find(loc => loc.id === product.locationId)
        return {
          center: [location.longitude, location.latitude],
          zoom: 8,
        }
      } else if (product.productType === this.G_PRODUCT_TYPE_VXC) {
        const aEndLocation = this.locations.find(loc => loc.id === product.aEnd.locationId)
        const bEndLocation = this.locations.find(loc => loc.id === product.bEnd.locationId)
        const bbox = bboxAcrossMeridian([aEndLocation.longitude, aEndLocation.latitude], [bEndLocation.longitude, bEndLocation.latitude])
        return {
          bounds: bbox,
          fitBoundsOptions: { padding: 200 },
        }
      } else if (product.productType === this.G_PRODUCT_TYPE_IX) {
        const location = this.locations.find(loc => loc.id === product.locationId)
        const ixCoordinates = await getIXLocationData(product.networkServiceType)

        const bbox = bboxAcrossMeridian([location.longitude, location.latitude], ixCoordinates)

        return {
          bounds: bbox,
          fitBoundsOptions: { padding: 200 },
        }
      }
    },
    /**
     * Creates HTML elements that are used for image markers etc.
     */
    createImages() {
      const NUM_ROCKET_IMAGES = 8
      for (let index = 0; index < NUM_ROCKET_IMAGES; index++) {
        const img = new Image()
        img.onload = () => {
          this.rockets.push(img)
        }

        const now = new Date()
        const month = now.getMonth() + 1
        const day = now.getDate()

        if (month === 10 && day > 10) {
          img.src = `/map-images/HalloweenRocket ${index + 1}.svg`
        } else if (month === 12 && day > 5 && day < 26) {
          img.src = `/map-images/SantaRocket ${index + 1}.svg`
        } else {
          img.src = `/map-images/Rocket ${index + 1}.svg`
        }
      }

      this.images.port_white = this.generateImageDataUrl('MEGAPORT', 'white')
      this.images.port_black = this.generateImageDataUrl('MEGAPORT', 'black')
      this.images.lag_white = this.generateImageDataUrl('LAG', 'white')
      this.images.lag_black = this.generateImageDataUrl('LAG', 'black')
      this.images.mve_white = this.generateImageDataUrl('MVE', 'white')
      this.images.mve_black = this.generateImageDataUrl('MVE', 'black')
      this.images.mcr_white = this.generateImageDataUrl('MCR', 'white')
      this.images.mcr_black = this.generateImageDataUrl('MCR', 'black')
      this.images.vxc_white = this.generateImageDataUrl('VXC', 'white')
      this.images.vxc_black = this.generateImageDataUrl('VXC', 'black')
      this.images.transitVxc_white = this.generateImageDataUrl('MegaportInternet', 'white')
      this.images.transitVxc_black = this.generateImageDataUrl('MegaportInternet', 'black')
      this.images.ix_white = this.generateImageDataUrl('IX', 'white')
      this.images.ix_black = this.generateImageDataUrl('IX', 'black')
    },
    /**
     * Since we don't directly have access to the SVG images, load them and turn them into data URLs.
     * this will be inefficient for large graphs, but will do for a proof of concept.
     */
    generateImageDataUrl(icon, color) {
      const IconClass = Vue.extend(MuMegaIcon)
      const iconInstance = new IconClass({
        propsData: {
          icon,
        },
      })
      const html = iconInstance.$mount().$el
      html.style.color = color
      const dataUrl = `data:image/svg+xml;base64,${Buffer.from(html.outerHTML).toString('base64')}`
      return dataUrl
    },
    fixSpiderOffsets(popupOffsets) {
      // Adjust the offsets so the popup doesn't appear right on top of the marker.
      popupOffsets.top[1] += SPIDER_MARKER_SIZE / 2
      popupOffsets['top-left'][1] += SPIDER_MARKER_SIZE / 2
      popupOffsets['top-right'][1] += SPIDER_MARKER_SIZE / 2
      popupOffsets.bottom[1] -= SPIDER_MARKER_SIZE / 2
      popupOffsets['bottom-left'][1] -= SPIDER_MARKER_SIZE / 2
      popupOffsets['bottom-right'][1] -= SPIDER_MARKER_SIZE / 2
      popupOffsets.left[0] += SPIDER_MARKER_SIZE / 2
      popupOffsets.right[0] -= SPIDER_MARKER_SIZE / 2
    },
    createSpiderifier() {
      const SPIDER_IMAGE_SIZE = 28
      this.spiderifier = new MapboxglSpiderifier(this.map, {
        customPin: true,
        animate: true,
        animationSpeed: 200,
        onClick: (e, spiderLeg) => {
          e.stopPropagation()
          const popupOffsets = MapboxglSpiderifier.popupOffsetForSpiderLeg(spiderLeg)
          this.fixSpiderOffsets(popupOffsets)

          if (spiderLeg.feature.type === 'Location') {
            this.showLocationPopup(spiderLeg.feature.locationId, spiderLeg.mapboxMarker.getLngLat(), popupOffsets)
          } else {
            this.showPortLikePopup(spiderLeg.feature.data, spiderLeg.mapboxMarker.getLngLat(), popupOffsets)
          }

        },
        markerWidth: SPIDER_MARKER_SIZE,
        markerHeight: SPIDER_MARKER_SIZE,
        circleFootSeparation: SPIDER_MARKER_SIZE - 5,
        spiralFootSeparation: SPIDER_MARKER_SIZE + 5,
        spiralLengthStart: SPIDER_MARKER_SIZE,
        circleSpiralSwitchover: 9,
        initializeLeg: spiderLeg => {
          const customPin = document.createElement('div')
          customPin.classList.add('spider-pin')
          customPin.style['background-color'] = spiderLeg.feature.color
          customPin.style['width'] = `${SPIDER_MARKER_SIZE}px`
          customPin.style['height'] = `${SPIDER_MARKER_SIZE}px`
          customPin.style['margin-left'] = `${-SPIDER_MARKER_SIZE / 2}px`
          customPin.style['margin-top'] = `${-SPIDER_MARKER_SIZE / 2}px`

          const imageName = spiderLeg.feature.image
          if (this.images[`${imageName}_white`]) {
            const img = document.createElement('img')
            img.setAttribute('src', this.images[`${imageName}_white`])
            img.setAttribute('alt', imageName)
            img.setAttribute('width', SPIDER_IMAGE_SIZE)
            img.style.color = 'white'
            customPin.appendChild(img)
          } else if (imageName === 'logo') {
            const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
            svg.setAttributeNS(null, 'viewBox', '0 0 117 164')
            svg.setAttributeNS(null, 'height', SPIDER_IMAGE_SIZE - 7)
            svg.style.color = 'white'
            const use = document.createElementNS('http://www.w3.org/2000/svg', 'use')
            use.setAttribute('href', '/map-images/mp-logo-solid.svg#img')
            svg.appendChild(use)
            customPin.appendChild(svg)
          }


          customPin.setAttribute('mptype', spiderLeg.feature.type)
          if (spiderLeg.feature.type === 'Location') {
            customPin.setAttribute('locationid', spiderLeg.feature.locationId)
          } else {
            customPin.setAttribute('productuid', spiderLeg.feature.data.productUid)
          }
          const popupOffsets = MapboxglSpiderifier.popupOffsetForSpiderLeg(spiderLeg)
          this.fixSpiderOffsets(popupOffsets)

          customPin.setAttribute('popupoffsets', JSON.stringify(popupOffsets))

          customPin.addEventListener('mouseenter', e => {
            e.stopPropagation()
            const element = e.target
            const offset = JSON.parse(element.getAttribute('popupoffsets'))
            this.showingSpiderDetail = true
            if (element.getAttribute('mptype') === 'Location') {
              const locationId = parseInt(element.getAttribute('locationid'))
              this.showHoverPopupForLocation(locationId, offset)
            } else {
              const productUid = element.getAttribute('productuid')
              this.showHoverPopupForPortLike(productUid, offset)
            }
          })
          customPin.addEventListener('mouseleave', e => {
            e.stopPropagation()
            this.showingSpiderDetail = false

            this.hoverPopup.remove()
            this.hoverPopup.setOffset(BASE_MARKER_POPUP_OFFSET)
          })

          spiderLeg.elements.pin.appendChild(customPin)
        },
      })
    },
    async dependentDataChanged() {
      this.emptyLocationRefreshKey++
      if (!this.map) {
        return
      }
      this.map.getSource('empty-locations-source')?.setData(this.emptyLocationSourceData)
      const sourceData = await this.occupiedLocationSourceData()
      this.map.getSource('occupied-locations-source')?.setData(sourceData)
      await this.updateConnectionAndMidpointSources()
      this.dependentDataReady = true
    },
    /**
     * IXs don't have a location as such but they do have a group metro which we can look up and find the location for. This is then used
     * to build our features for the connections.
     */
    async getIxPseudoLocationFeatures() {
      const ixs = this.myConnections.filter(connection => connection.productType === this.G_PRODUCT_TYPE_IX)
      const ixFeatures = []
      let ixCounter = 100000 // So it can't overlap with the location ids
      for (const ix of ixs) {
        const existingData = ixFeatures.find(feature => feature.properties.networkServiceType === ix.networkServiceType)
        if (!existingData) {
          const ixCoordinates = await getIXLocationData(ix.networkServiceType)
          ixCounter++
          ixFeatures.push({
            id: ixCounter,
            type: 'Feature',
            properties: { // We want something to tell us how to draw it, something that references it, and anything we will search on
              locationType: 'IX',
              networkServiceType: ix.networkServiceType,
            },
            geometry: {
              type: 'Point',
              coordinates: ixCoordinates,
            },
          })
        }
      }
      return ixFeatures
    },
    mapContainerResized() {
      if (this.destroyed) {
        return
      }
      const displayMode = getMapSize()
      this.setDisplaySize(displayMode, false, true)
      const container = document.getElementById('mapbox-container')
      const footer = document.getElementById('site-footer')
      const widgets = document.getElementById('widgets')
      const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      const containerTop = container.getBoundingClientRect().top
      const footerHeight = footer?.getBoundingClientRect().height ?? 0

      let widgetsHeight = 0
      if (displayMode === 'small' && widgets) {
        widgetsHeight = widgets.getBoundingClientRect().height
        widgetsHeight += parseInt(window.getComputedStyle(widgets).getPropertyValue('margin-top'))
        widgetsHeight += parseInt(window.getComputedStyle(widgets).getPropertyValue('margin-bottom'))
      }
      if (this.initializationError) {
        container.style.height = 'fit-content'
      } else {
        let requiredHeight = viewportHeight - containerTop - footerHeight - widgetsHeight
        if (requiredHeight < 500) {
          requiredHeight = 500
        }
        container.style.height = `${requiredHeight}px`
      }

      if (this.map) {
        this.map.resize()
        const canvas = document.getElementById('overlay')
        if (canvas) {
          // Make sure scaling is 1:1
          canvas.width = this.map.getCanvas().width / window.devicePixelRatio
          canvas.height = this.map.getCanvas().height / window.devicePixelRatio
        }
        this.calculateEdgeOfWorldOffset()
      }
    },
    calculateEdgeOfWorldOffset() {
      if (this.map.getProjection().name !== 'globe') {
        this.edgeOfWorldOffset = -1
        this.animateRocket = false
        return
      }
      // Work out the coordinates of that screen position - this will be the nearest coordinates
      const coordinates = this.map.unproject([this.map.getCanvas().width / window.devicePixelRatio / 2, 0])
      // This takes the coordinates back to screen coordinates. If the y value is <= 0 then the map is filling the screen, so we don't want to display the  rodket
      const screen = this.map.project(coordinates)
      // Screen.y is the distance from the edge to the world. Due to rounding errors, we need to round the value
      this.edgeOfWorldOffset = Math.round(screen.y)
      // Stop the animation if the edge of the world is no longer showing
      if (this.edgeOfWorldOffset < 0) {
        this.animateRocket = false
      }
    },
    /**
     * Changes the language of the displayed map.
     *
     * @param {string} newLanguage
     */
    languageChanged(newLanguage) {
      if (!this.map || !this.geocoder) {
        this.$notify({
          title: this.$t('map.not-ready-title'),
          message: this.$t('map.not-ready-message'),
        })
        return
      }
      this.map.setStyle(this.mapboxlanguage.setLanguage(this.map.getStyle(), newLanguage.code))
      this.map.language = newLanguage.code
      this.geocoder.setLanguage(newLanguage.code)
      this.geocoder.setPlaceholder(this.$t('general.search'))
    },
    /**
     * Add the controls associated with the map
     */
    addControls() {
      this.mapboxlanguage = new MapboxLanguage({
        defaultLanguage: this.language.code,
      })
      this.map.addControl(this.mapboxlanguage)

      this.geocoder = new MapboxGeocoder({
        accessToken: mapboxGl.accessToken,
        mapboxgl: mapboxGl,
        clearOnBlur: true,
        limit: 15,
        externalGeocoder: findKnownFeatures,
        marker: false,
        render: this.renderSearchResult,
        placeholder: this.$t('general.search'),
        getItemValue: this.renderSearchTitle,
      })
      // Subscribe to this event so that we can do post-processing depending on what type of thing was selected
      this.geocoder.on('result', found => {
        const result = found.result

        if (this.searchResultMarker) {
          this.searchResultMarker.remove()
          this.searchResultMarker = null
        }

        if (result.properties.mpType) {
          if (result.properties.mpType === 'EmptyLocation') {
            this.closeAllPopups()

            const listener = () => {
              this.showLocationPopup(result.properties.locationId, result.geometry.coordinates)
              this.map.off('idle', listener)
            }
            this.map.on('idle', listener)
          } else if (result.properties.mpType === 'OccupiedLocation') {
            this.closeAllPopups()
            this.removeSpider()
            const listener = async () => {
              const sourceData = await this.occupiedLocationSourceData()
              const feature = sourceData.features.find(f => Math.abs(f.geometry.coordinates[0] - result.geometry.coordinates[0]) < EPSILON && Math.abs(f.geometry.coordinates[1] - result.geometry.coordinates[1]) < EPSILON)
              this.spiderifyFeature(feature)
              this.spiderifier.each(spiderLeg => {
                if (spiderLeg.feature.type === 'Location') {
                  const popupOffsets = MapboxglSpiderifier.popupOffsetForSpiderLeg(spiderLeg)
                  this.fixSpiderOffsets(popupOffsets)
                  this.showLocationPopup(spiderLeg.feature.locationId, spiderLeg.mapboxMarker.getLngLat(), popupOffsets)
                }
              })
              this.map.off('idle', listener)
            }
            this.map.on('idle', listener)
          } else if (result.properties.productUid) {
            if (result.bbox) {
              this.map.fitBounds(result.bbox, {
                padding: 200,
              })
            }
            this.showPopupForUid(result.properties.productUid)
          }
        } else {
          // It is a standard result - show a pin at the location
          this.searchResultMarker = new mapboxGl.Marker().setLngLat(result.geometry.coordinates).addTo(this.map)
        }
      })
      const geocoderElement = document.getElementById('geocoder')
      geocoderElement.appendChild(this.geocoder.onAdd(this.map))
      geocoderElement.children[0].classList.add('el-input')

      const geocoderInput = document.querySelector('#geocoder input')
      geocoderInput.classList.add('el-input__inner')
    },
    addListeners() {
      this.map.on('zoomstart', () => this.zoomStarted())
      this.map.on('zoomend', async () => await this.zoomEnded())

      this.map.on('load', async () => await this.mapLoaded())
      this.map.on('click', () => this.handleMapClick())
      this.map.on('mousedown', e => this.handleMapMouseDown(e))
      this.map.on('mouseup', () => this.handleMapMouseUp())
      this.map.on('mousemove', e => this.handleMapMouseMoved(e))
      this.map.on('data', e => this.sourceDataLoaded(e))
      this.map.on('render', () => this.debouncedHandleMapRender())
      this.map.on('idle', () => this.handleMapIdle())

      this.map.on('mouseenter', 'empty-locations-layer', e => this.handleEmptyLocationsLayerMouseEnter(e))
      this.map.on('mouseleave', 'empty-locations-layer', () => this.handleEmptyLocationsLayerMouseLeave())
      this.map.on('click', 'empty-locations-layer', e => this.handleEmptyLocationsLayerClick(e))

      this.map.on('mouseenter', 'occupied-locations-layer', e => this.handleOccupiedLocationsLayerMouseEnter(e))
      this.map.on('mouseleave', 'occupied-locations-layer', () => this.handleOccupiedLocationsLayerMouseLeave())
      this.map.on('click', 'occupied-locations-layer', e => this.handleOccupiedLocationsLayerClick(e))

      this.map.on('mouseenter', 'empty-location-clusters-layer', e => this.handleEmptyLocationClusterMouseEnter(e))
      this.map.on('mouseleave', 'empty-location-clusters-layer', () => this.handleEmptyLocationClusterMouseLeave())
      this.map.on('click', 'empty-location-clusters-layer', e => this.handleEmptyLocationClusterClick(e))

      this.map.on('mouseenter', 'connection-midpoints-layer', e => this.handleMidpointMouseEnter(e))
      this.map.on('mouseleave', 'connection-midpoints-layer', () => this.handleMidpointMouseLeave())
      this.map.on('click', 'connection-midpoints-layer', e => this.handleMidpointClick(e))

      this.map.getCanvas().addEventListener('keydown', e => {
        if (e.key === 'ArrowUp' && e.shiftKey) {
          this.map.setBearing(0)
        }
      })
    },
    zoomStarted() {
      this.animateRocket = false
      this.closeAllPopups()
      this.removeSpider()
    },
    handleMapIdle() {
      this.displayRocketIfRequired()
    },
    displayRocketIfRequired() {
      // Work out whether to display the rocket
      this.calculateEdgeOfWorldOffset()

      if (this.edgeOfWorldOffset <= 0 || this.showingPopup) {
        return // Map fills the page - do not display
      }
      this.animateRocket = true

      const canvas = document.getElementById('overlay')
      if (!canvas) {
        return
      }
      const ctx = canvas.getContext('2d')
      const SPEED_FACTOR = 100
      const ROCKET_HEIGHT = 250
      const ROCKET_SCALE = 0.7

      let counter = 0
      let imageIndex = 0
      const animateRocketFrame = timestamp => {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        if (!this.animateRocket || !this.rockets.length) {
          return // Bail out if no longer required
        }
        const radius = canvas.height / 2 - this.edgeOfWorldOffset
        const degrees = (timestamp / SPEED_FACTOR) % 360
        if (counter++ % 2) {
          imageIndex = (imageIndex + 1) % this.rockets.length
        }
        const img = this.rockets[imageIndex]

        ctx.save()
        ctx.translate(canvas.width / 2, canvas.height / 2)
        ctx.rotate(degrees * Math.PI / 180)
        const scaleFactor = radius / canvas.height * ROCKET_SCALE
        ctx.scale(scaleFactor, scaleFactor)
        ctx.drawImage(img, -img.width / 2 - (radius / scaleFactor) - ROCKET_HEIGHT, -img.height / 4)
        ctx.restore()

        window.requestAnimationFrame(animateRocketFrame)
      }
      window.requestAnimationFrame(animateRocketFrame)
    },
    async zoomEnded() {
      this.zoom = this.map.getZoom()
      await this.updateConnectionAndMidpointSources()
    },
    async mapLoaded() {
      // Can't add sources until the map is loaded, and the layers rely on the sources, so we can't add them until the sources are loaded.
      await this.addLocationsSources() // Locations come in two flavours: empty and occupied. Both sources are added here
      this.addEmptyLocationsLayers() // This adds layers for the empty locations and associated clusters
      this.addConnectionsAndMidpointSources() // Adds the data source for connections and midpoints based on the clustering of the occupied locations
      this.addConnectionMidpointsLayer() // Adds the layer to display the midpoints of the connections
      this.configureConnectionsLayers() // Adds the layers for the connections
      this.addOccupiedLocationsLayer() // Adds the layer for the occupied locations on top of everything else. Note that the clusters are drawn separately in markers
    },
    removeSpider() {
      this.spiderifier?.unspiderfy()
      this.showingSpiderDetail = false

      if (this.hiddenOccupiedLocationId) {
        this.map.setFeatureState({
          source: 'occupied-locations-source',
          id: this.hiddenOccupiedLocationId,
        }, {
          visible: true,
        })
        this.hiddenOccupiedLocationId = null
      }
    },
    handleMapClick() {
      this.removeSpider()
      this.hoverPopup.setOffset(BASE_MARKER_POPUP_OFFSET)
    },
    handleMapMouseDown(e) {
      if (e.originalEvent.altKey) {
        this.map.dragPan.disable()
      }
    },
    handleMapMouseUp() {
      this.map.dragPan.enable()
    },
    handleMapMouseMoved(e) {
      if (!this.map.dragPan.isEnabled()) {
        this.map.setZoom(this.map.getZoom() + e.originalEvent.movementY / 100)
      }
    },
    handleMapRendered() {
      if (this.destroyed || this.pendingPostLoadNavigation || this.companyContextLoading) {
        return // Don't update parameters if the user is on some other page
      }
      // Get the current view (no need to get pitch since we are not allowing users to change that)
      const zoom = this.map.getZoom()
      const { lng, lat } = this.map.getCenter()
      const bearing = this.map.getBearing()
      const projection = this.map.getProjection().name
      const oldSettingsString = stableStringify(getMapDisplay())
      const newSettings = {
        zoom,
        longitude: lng,
        latitude: lat,
        bearing,
        projection,
      }
      const newSettingsString = stableStringify(newSettings)
      if (oldSettingsString !== newSettingsString) {
        setMapDisplay(newSettings)
      }
    },
    setProjection(projection) {
      this.projection = projection
      this.map.setProjection(projection)
      if (projection === 'mercator') {
        // This is the only way I have found to address an issue where if you are switching from globe to mercator, the map will
        // be in the wrong position if you were centered near the north pole. Just adjust the center to be very very slightly moved
        const { lng, lat } = this.map.getCenter()
        this.map.setCenter([(lng + 180.000001) % 360 - 180, lat])
      }
      this.displayRocketIfRequired()

      captureEvent(`dashboard.map.projection-${projection}.click`)
    },
    async navigatePostLoad() {
      if (!this.map || !this.pendingPostLoadNavigation || !this.servicesReady || !this.locationsReady || !this.dependentDataReady) {
        return
      }
      if (getFocussedUid()) {
        // The result will give us either center and zoom or bounds and fitBoundsOptions
        const settings = await this.calculateSettingsForSelectedUid()
        if (settings.zoom && settings.center) {
          this.map.setCenter(settings.center)
          this.map.setZoom(settings.zoom)
        } else if (settings.bounds) {
          this.map.fitBounds(settings.bounds, { padding: 200 })
        }
        this.showPopupForUid(getFocussedUid())
        // It has been processed, so cancel it out
        setFocussedUid(null)
      } else if (getMapDisplay().zoom) {
        const { zoom, longitude, latitude, bearing, projection = 'globe' } = getMapDisplay()
        this.map.setCenter([longitude, latitude])
        this.map.setZoom(zoom)
        this.map.setBearing(bearing)
        this.map.setProjection(projection)
      } else {
        // Fit all services in to view
        const bounds = await this.calculateSettingsForAllServices()
        this.map.fitBounds(bounds, { padding: 200, animate: false })
      }
      this.pendingPostLoadNavigation = false
    },
    async occupiedLocationSourceData() {
      const ixPseudoLocationFeatures = await this.getIxPseudoLocationFeatures()
      return {
        type: 'FeatureCollection',
        features: [...this.locationFeatures.filter(feature => this.usedLocationIds.has(feature.properties.locationId)), ...ixPseudoLocationFeatures],
      }
    },
    /**
     * This needs to be used to calculate both the connection line layer and the connection centrepoints. It will become outdated
     * when either the zoom changes or the occupied locations changes.
     */
    async clustersForConnectionsAndZoom() {
      const sc = new Supercluster({
        minZoom: this.MAPBOX_MIN_ZOOM,
        maxZoom: this.MAPBOX_MAX_ZOOM,
        radius: MAPBOX_CLUSTER_RADIUS,
      })
      const sourceData = await this.occupiedLocationSourceData()
      sc.load(sourceData.features)
      const clusters = sc.getClusters([-180, -90, 180, 90], this.zoom)
      // Each cluster item returned will either have a cluster property or the raw data. In order to make this more useful we will look up the
      // children of the cluster here and return it in the properties data.
      return clusters.map(item => {
        if (item.properties.cluster) {
          item.properties.children = sc.getLeaves(item.properties.cluster_id, Infinity)
        }
        return item
      })
    },
    /**
     * Work out the required zoom level to make the specified location or IX be un-clustered
     */
    async zoomLevelForNonClusteredLocation(locationIdOrIX) {
      const sc = new Supercluster({
        minZoom: this.MAPBOX_MIN_ZOOM,
        maxZoom: this.MAPBOX_MAX_ZOOM,
        radius: MAPBOX_CLUSTER_RADIUS,
      })
      const sourceData = await this.occupiedLocationSourceData()
      sc.load(sourceData.features)

      let zoom = 10
      do {
        const clusters = sc.getClusters([-180, -90, 180, 90], zoom)
        const unClustered = clusters.find(cluster => cluster.properties.locationId === locationIdOrIX || cluster.properties.networkServiceType === locationIdOrIX)
        if (unClustered) {
          return zoom
        }
        zoom++
      } while (zoom <= 20)
      return zoom
    },

    /**
     * We use two sources for location type pins - one for the empty locations, and one for both the occupied locations and
     * the IX pseudo-locations. This wasy we can have clustering of unoccupied locations separate from the occupied locations
     * and deal with them in a manner more appropriate to the usage.
     */
    async addLocationsSources() {
      this.map.addSource('empty-locations-source', {
          type: 'geojson',
          data: this.emptyLocationSourceData,
          cluster: true,
          clusterRadius: MAPBOX_CLUSTER_RADIUS,
      })

      this.map.addSource('occupied-locations-source', {
          type: 'geojson',
          data: await this.occupiedLocationSourceData(),
          cluster: true,
          clusterRadius: MAPBOX_CLUSTER_RADIUS,
      })
    },
    /**
     * As mentioned above, there are sources for empty and occupied locations. We add the empty one first so that we can
     * see the occupied ones on top.
     */
    addEmptyLocationsLayers() {
      // The empty locations layer will display the data centres
      this.map.addLayer({
          id: 'empty-locations-layer',
          type: 'symbol',
          source: 'empty-locations-source',
          filter: ['!has', 'point_count'],
          layout: {
            'icon-anchor': 'bottom',
            'icon-image': 'empty-location-pin',
            'icon-size': 1,
            visibility: this.emptyLocationsVisible ? 'visible' : 'none',
          },
        })

      this.map.addLayer({
          id: 'empty-location-clusters-layer',
          type: 'circle',
          source: 'empty-locations-source',
          filter: ['has', 'point_count'],
          layout: {
            visibility: this.emptyLocationsVisible ? 'visible' : 'none',
          },
          paint: {
            'circle-color': THIRD_PARTY_COLOR,
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              18, // Use size...
              10, // Up to count...
              22,
              100,
              24, // From there on
            ],
          },
        })

      this.map.addLayer({
          id: 'empty-location-cluster-counts-layer',
          type: 'symbol',
          source: 'empty-locations-source',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-font': ['Arial Unicode MS Bold'],
            'text-size': 20,
            visibility: this.emptyLocationsVisible ? 'visible' : 'none',
            'text-allow-overlap': true,
          },
          paint: {
            'text-color': '#ffffff',
          },
        })
    },
    addOccupiedLocationsLayer() {
      this.map.addLayer({
          id: 'occupied-locations-layer',
          type: 'symbol',
          source: 'occupied-locations-source',
          filter: ['!has', 'point_count'],
          layout: {
            'icon-anchor': 'bottom',
            'icon-image': [
              'match', // Match operator
              ['get', 'locationType', ['properties']], // Get the locationType from the properties object
              'Location', // If it's a normal location...
              'occupied-location-pin', // Display using the occupied location pin
              'ix-pin', // Else, it must be an IX
            ],
            'icon-size': 1,
          },
          paint: {
            'icon-opacity': ['case', ['boolean', ['feature-state', 'visible'], true], 1, 0],
          },
        })
    },
    addConnectionsAndMidpointSources() {
      this.map.addSource('connections-source', {
          type: 'geojson',
          lineMetrics: true,
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        })
      this.map.addSource('connection-midpoints-source', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        })

      // Fill in the actual data
      this.updateConnectionAndMidpointSources()
    },
    clusterMatchesLocationId(clusterItem, locationId) {
      if (clusterItem.properties.cluster) {
        const foundLocation = clusterItem.properties.children.find(child => child.properties.locationId === locationId)
        if (foundLocation) {
          return true
        }
      }
      // We must be looking in an individual one.
      if (clusterItem.properties.locationId === locationId) {
        return true
      }
      return false
    },
    async updateConnectionAndMidpointSources() {
      if (!this.locationsReady || this.locationFeatures.length === 0) {
        return
      }
      const locationPairs = []
      const clusters = await this.clustersForConnectionsAndZoom()
      // Note that myConnections will have duplicate entries for private VXCs, so make sure to only add them once
      for (const connection of this.myConnections) {
        let startCluster = undefined
        let endCluster = undefined
        if (connection.productType === this.G_PRODUCT_TYPE_VXC) {
          // We always need to have the locations in the same order so that we can compact duplicates even if they are
          // going in the other direction.
          const aLocation = this.locations.find(location => location.id === connection.aEnd.locationId)
          const bLocation = this.locations.find(location => location.id === connection.bEnd.locationId)
          const lineCoordinates = [[aLocation.longitude, aLocation.latitude], [bLocation.longitude, bLocation.latitude]]
          normaliseLineCoordinates(lineCoordinates)
          aLocation.fixedCoordinates = lineCoordinates[0]
          bLocation.fixedCoordinates = lineCoordinates[1]
          const sortedLocations = [aLocation, bLocation].sort((a, b) => {
            if (a.fixedCoordinates[0] < b.fixedCoordinates[0]) {
              return -1
            }
            if (a.fixedCoordinates[0] > b.fixedCoordinates[0]) {
              return 1
            }
            if (a.fixedCoordinates[1] < b.fixedCoordinates[1]) {
              return -1
            }
            if (a.fixedCoordinates[1] > b.fixedCoordinates[1]) {
              return 1
            }
            return 0
          })

          // Find the cluster or item that contains the start
          startCluster = clusters.find(item => this.clusterMatchesLocationId(item, sortedLocations[0].id))
          endCluster = clusters.find(item => this.clusterMatchesLocationId(item, sortedLocations[1].id))
        } else {
          // Must be an IX
          const ixClusters = []
          ixClusters.push(clusters.find(item => this.clusterMatchesLocationId(item, connection.locationId)))
          ixClusters.push(clusters.find(clusterItem => {
            if (clusterItem.properties.cluster) {
              const foundIX = clusterItem.properties.children.find(child => child.properties.networkServiceType === connection.networkServiceType)
              if (foundIX) {
                return true
              }
            }
            // We must be looking in an individual one.
            if (clusterItem.properties.networkServiceType === connection.networkServiceType) {
              return true
            }
            return false
          }))
          const lineCoordinates = [ixClusters[0].geometry.coordinates, ixClusters[1].geometry.coordinates]
          normaliseLineCoordinates(lineCoordinates)
          ixClusters[0].fixedCoordinates = lineCoordinates[0]
          ixClusters[1].fixedCoordinates = lineCoordinates[1]

          ixClusters.sort((a, b) => {
            if (a.fixedCoordinates[0] < b.fixedCoordinates[0]) {
              return -1
            }
            if (a.fixedCoordinates[0] > b.fixedCoordinates[0]) {
              return 1
            }
            if (a.fixedCoordinates[1] < b.fixedCoordinates[1]) {
              return -1
            }
            if (a.fixedCoordinates[1] > b.fixedCoordinates[1]) {
              return 1
            }
            return 0
          })
          startCluster = ixClusters[0]
          endCluster = ixClusters[1]
        }

        if (!startCluster || !endCluster) {
          continue // This would only happen if the data hasn't finished loading
        }

        // The PairData will be of the format {start, connections, end} where start and end are either standalone items or clusters.
        // If they are a cluster then we just need to match on the cluster id. If they are an individual item then we match on the
        // locationId.
        let existingPairData = locationPairs.find(pairData => {
          let foundStart = false
          if (startCluster.properties.cluster) {
            foundStart = startCluster.properties.cluster_id === pairData.start.properties.cluster_id
          } else {
            foundStart = startCluster.properties.locationId === pairData.start.properties.locationId
          }
          if (foundStart) {
            // For the end one it's slightly more complex in that we need to check the type and if VXC match on locationId, otherwise
            // match on networkServiceType
            if (endCluster.properties.cluster) {
              return endCluster.properties.cluster_id === pairData.end.properties.cluster_id
            }
            if (endCluster.properties.locationType === 'Location') {
              return endCluster.properties.locationId === pairData.end.properties.locationId
            } else if (endCluster.properties.locationType === 'IX') {
              return endCluster.properties.networkServiceType === pairData.end.properties.networkServiceType
            }
            throw new Error(`Unknown location type: ${endCluster.properties.locationType}`)
          }
          return false
        })
        if (!existingPairData) {
          existingPairData = {
            start: startCluster,
            connections: [],
            end: endCluster,
          }
          locationPairs.push(existingPairData)
        }
        if (!existingPairData.connections.find(c => c.productUid === connection.productUid)) {
          existingPairData.connections.push(connection)
        }
      }

      // The above will include all connections, but we don't want to have any items that go from the same place to the same place.
      // This can only happen if the start and end are both in the same cluster.
      const drawableConnections = locationPairs.filter(pair => {
        return !(pair.start.properties.cluster && pair.end.properties.cluster && pair.start.properties.cluster_id === pair.end.properties.cluster_id)
      })

      // Findings:
      // 1. We can not use expressions for the line-gradient property.
      // 2. Threebox does not support globe projection.
      // 3. In order to display gradients of arbitrary stops, we need a layer per connection line.
      // 4. Doing this will mean keeping track of the layers and replacing them when the data updates.
      // Plan: Create a separate data item for each line, sorted by up status, and work out the offset based on its angle using Turf.bearing and the number of connections.
      // Use this to set the line-translate property.

      const features = []
      let counter = 0

      for (const item of drawableConnections) {
        // Create a feature for each connection in the drawable connection so that we can display multiple lines.

        // We want to have the connections ordered by up status so that we can display the up ones on top of the down ones and unknowns on the bottom.
        item.connections.sort((a, b) => {
          let aStatus = undefined

          if (a.provisioningStatus !== this.G_PROVISIONING_LIVE) {
            aStatus = STATUS_DOWN
          } else if (a.up === true) {
            aStatus = STATUS_UP
          } else if (a.up === false) {
            aStatus = STATUS_DOWN
          } else {
            aStatus = STATUS_UNKNOWN
          }

          let bStatus = undefined

          if (b.provisioningStatus !== this.G_PROVISIONING_LIVE) {
            bStatus = STATUS_DOWN
          } else if (b.up === true) {
            bStatus = STATUS_UP
          } else if (b.up === false) {
            bStatus = STATUS_DOWN
          } else {
            bStatus = STATUS_UNKNOWN
          }

          return bStatus - aStatus
        })

        // In order to interpolate the colours to the right settings, we need to know the length of the line and the start and end proportion
        const startPoint = turf.point(item.start.geometry.coordinates)
        const endPoint = turf.point(item.end.geometry.coordinates)
        // const length = turf.length(turf.lineString([item.start.geometry.coordinates, item.end.geometry.coordinates]), { units: 'kilometers' })
        const bearing = turf.bearing(startPoint, endPoint)
        const angle = (450 - bearing) % 360

        let startOffset = 0

        if (item.connections.length > 1) {
          startOffset = (item.connections.length * LINE_WIDTH + (item.connections.length - 1) * GAP_WIDTH) / -2
        }

        const coordinates = splitCoordinates(item.start.geometry.coordinates, item.end.geometry.coordinates)

        for (const connection of item.connections) {
          let status = undefined

          if (connection.provisioningStatus !== this.G_PROVISIONING_LIVE) {
            status = STATUS_DOWN
          } else if (connection.up === true) {
            status = STATUS_UP
          } else if (connection.up === false) {
            status = STATUS_DOWN
          } else {
            status = STATUS_UNKNOWN
          }

          for (let i = 0; i < coordinates.length; i += 2) {
            const startCoordinates = coordinates[i]
            const endCoordinates = coordinates[i + 1]
            features.push({
              type: 'Feature',
              properties: {
                counter,
                status,
                translation: [
                  startOffset * Math.sin(angle * Math.PI / 180),
                  startOffset * Math.cos(angle * Math.PI / 180),
                ],
                startProportion: startCoordinates[2],
                endProportion: endCoordinates[2],
                ...item,
              },
              geometry: {
                type: 'LineString',
                coordinates: [
                  [
                    startCoordinates[0],
                    startCoordinates[1],
                  ],
                  [
                    endCoordinates[0],
                    endCoordinates[1],
                  ],
                ],
              },
            })
            counter++
          }
          startOffset += LINE_WIDTH + GAP_WIDTH
        }
      }

      this.map.getSource('connections-source')?.setData({
        type: 'FeatureCollection',
        features,
      })

      this.map.getSource('connection-midpoints-source')?.setData({
        type: 'FeatureCollection',
        features: drawableConnections.map(item => {
          const coordinates = midpointAcrossMeridian(item.start.geometry.coordinates, item.end.geometry.coordinates)
          let hasUp = false
          let hasDown = false
          let hasUnknown = false
          for (const connection of item.connections) {
            if (connection.provisioningStatus !== this.G_PROVISIONING_LIVE) {
              hasDown = true
            } else if (connection.up === true) {
              hasUp = true
            } else if (connection.up === false) {
              hasDown = true
            } else {
              hasUnknown = true
            }
          }
          let iconImage = undefined
          if (hasUp && !hasDown && !hasUnknown) {
            iconImage = 'midline-connection-up'
          } else if (!hasUp && hasDown && !hasUnknown) {
            iconImage = 'midline-connection-down'
          } else if (!hasUp && !hasDown && hasUnknown) {
            iconImage = 'midline-connection-unknown'
          } else {
            iconImage = 'midline-connection-mixed'
          }
          return {
            type: 'Feature',
            properties: {
              ...item,
              iconImage,
            },
            geometry: {
              type: 'Point',
              coordinates,
            },
          }
        }),
      })
    },
    /**
     * The connections laysers are dynamic depending on the data, so we need to keep track of them and remove and re-add them when the data changes.
     *
     * They need to be added behind the connection-midpoints-layer so we will check that that layer is available first.
     */
    configureConnectionsLayers() {
      if (!this.map.getLayer('connection-midpoints-layer') || !this.map.isSourceLoaded('connections-source')) {
        return // Not ready yet
      }

      // Remove all the previous connection layers
      const layers = this.map.getStyle().layers
      for (const layer of layers) {
        if (layer.id.startsWith('connection-layer-')) {
          this.map.removeLayer(layer.id)
        }
      }

      // Add the new ones
      const features = this.map.getSource('connections-source')._data.features
      for (let i = 0; i < features.length ; i++) {
        const feature = features[i]

        let startColor = undefined
        let endColor = undefined
        switch (feature.properties.status) {
          case STATUS_UP:
            startColor = UP_COLOR_START
            endColor = UP_COLOR_END
            break
          case STATUS_DOWN:
            startColor = DOWN_COLOR_START
            endColor = DOWN_COLOR_END
            break
          case STATUS_UNKNOWN:
            startColor = UNKNOWN_COLOR_START
            endColor = UNKNOWN_COLOR_END
            break
        }

        this.map.addLayer({
          id: `connection-layer-${i}`,
          type: 'line',
          source: 'connections-source',
          filter: ['==', ['get', 'counter', ['properties']], i],
          paint: {
            'line-translate': feature.properties.translation,
            'line-translate-anchor': 'map',
            'line-color': 'white',
            'line-width': LINE_WIDTH,
            'line-gradient': [
              'interpolate',
              ['linear'],
              ['line-progress'],
              0,
              interpolateColors(startColor, endColor, feature.properties.startProportion),
              1,
              interpolateColors(startColor, endColor, feature.properties.endProportion),
            ],
          },
        }, 'connection-midpoints-layer')
      }
    },
    addConnectionMidpointsLayer() {
      this.map.addLayer({
          id: 'connection-midpoints-layer',
          type: 'symbol',
          source: 'connection-midpoints-source',
          layout: {
            'icon-anchor': 'center',
            'icon-image': ['get', 'iconImage', ['properties']],
            'icon-size': 1,
          },
        })
    },
    showHoverPopupForLocation(locationId, offset = BASE_MARKER_POPUP_OFFSET) {
      const location = this.locations.find(loc => loc.id === locationId)

      const supportedProducts = []
      if (location.products.megaport?.length && !this.disabledFeatures.addPort) {
        supportedProducts.push(`${this.$t('productNames.ports')}: ${location.products.megaport.map(speed => convertSpeed(speed * 1000)).join(', ')}`)
      }
      if (location.products.mcr2) {
        supportedProducts.push(this.$t('productNames.mcrs'))
      }
      if (location.products.mve?.some(mve => mve.sizes.length > 0)) {
        const vendors = new Set()
        for (const mve of location.products.mve) {
          if (mve.sizes.length) {
            vendors.add(mve.vendor)
          }
        }
        supportedProducts.push(`${this.$t('productNames.mves')}: ${[...vendors].join(', ')}`)
      }

      this.hoverPopup.setOffset(offset).setHTML(`
        <p class="font-weight-400">${location.formatted.long}</p>
        <p>${this.$t('map.supported-products')}</p>
        <ul>${supportedProducts.map(item => `<li>${item}</li>`).join('')}</ul>
        <p><strong>${this.$t('map.click-empty-location')}</strong></p>
      `).setLngLat([location.longitude, location.latitude]).addTo(this.map)
    },
    showHoverPopupForPortLike(productUid, offset) {
      const product = findPortOrPartnerInfo(productUid)
      const location = this.locations.find(loc => loc.id === product.locationId)

      let productInfo = ''
      if (!product.productName) {
        productInfo = product.companyName
      } else if (product.productType === this.G_PRODUCT_TYPE_MEGAPORT) {
        if (product.aggregationId || product.lagPortCount) {
          productInfo = `${this.$t('productNames.lag')} ${convertSpeed(product._aggSpeed)}`
        } else {
          productInfo = `${this.$t('productNames.port')} ${convertSpeed(product.speed || product.portSpeed)}`
        }
      } else if (product.productType === this.G_PRODUCT_TYPE_MCR2) {
        productInfo = `${this.$t('productNames.mcr')} ${convertSpeed(product.speed || product.portSpeed)}`
      } else if (product.productType === this.G_PRODUCT_TYPE_MVE) {
        const sizeText = product.provisioningStatus === this.G_PROVISIONING_DESIGN ? product.vendorConfig?.mveLabel || capitalizeFirstOnly(product.vendorConfig?.productSize) : product.mveLabel
        productInfo = `${this.$t('productNames.mve')} ${this.$t('ports.vendor')}: ${product.vendor || product.vendorConfig?._vendor}, ${this.$t('general.size')}: ${sizeText}`
      }

      const productDetails = product.productName || product.title

      this.hoverPopup.setOffset(offset).setHTML(`
        <p class="font-weight-400">${productInfo}</p>
        <p>${productDetails}</p>
        <p><strong>${this.$t('map.click-details')}</strong></p>
        `).setLngLat([location.longitude, location.latitude]).addTo(this.map)
    },
    handleEmptyLocationsLayerMouseEnter(e) {
      if (this.hoverPopup.isOpen()) {
        return // We have a lower priority than anything else
      }
      // Change the cursor to a pointer when the mouse is over the places layer.
      this.map.getCanvas().style.cursor = 'pointer'

      const locationId = e.features[0].properties.locationId

      this.showHoverPopupForLocation(locationId)
    },
    handleEmptyLocationsLayerMouseLeave() {
      // Change it back to a hand when it leaves.
      this.map.getCanvas().style.cursor = ''
      // No need to show the popup any more
      this.hoverPopup.remove()
    },
    closeAllPopups() {
      const popups = document.getElementsByClassName('mapboxgl-popup')
      for (const popup of popups) {
        popup.remove()
      }
    },
    showPartnerPortPopup(product, coordinates, offset = BASE_MARKER_POPUP_OFFSET) {
      const popupInstance = new PartnerPortPopup({
        propsData: {
          productUid: product.productUid,
        },
        store: this.$store,
        router: this.$router,
        i18n: this.$i18n,
      }).$mount()
      popupInstance.$on('close', () => {
        this.closeAllPopups()
        this.removeSpider()
      })
      popupInstance.$on('navigateToUid', async productUid => {
        const settings = await this.calculateSettingsForSelectedUid(productUid)
        if (settings.zoom && settings.center) {
          this.map.setCenter(settings.center)
          this.map.setZoom(settings.zoom)
        } else if (settings.bounds) {
          this.map.fitBounds(settings.bounds, { padding: 200 })
        }
        this.showPopupForUid(productUid)
      })
      popupInstance.$on('navigateToIx', async ixName => {
        await this.navigateToIx(ixName)
      })

      const popup = new mapboxGl.Popup({
        maxWidth: '70%',
        offset,
        className: 'mp-popup',
      })
        .setDOMContent(popupInstance.$el)
        .setLngLat(coordinates)
        .addTo(this.map)
      // This is needed to ensure that the popup ends up in the right position and not off
      // the edge of the screen.
      this.$nextTick(() => {
        popup.setLngLat(coordinates)
      })

      this.animateRocket = false
      this.showingPopup = true
      popup.on('close', () => {
        this.removeSpider()
        this.showingPopup = false
        this.displayRocketIfRequired()
      })
    },
    showPortLikePopup(product, coordinates, offset = BASE_MARKER_POPUP_OFFSET) {
      this.hoverPopup.remove()
      this.closeAllPopups()

      if (!product.productName || product.thirdParty) {
        this.showPartnerPortPopup(product, coordinates, offset)
        return
      }

      const popupInstance = new PortLikePopup({
        propsData: {
          productUid: product.productUid,
          disabledFeatures: providers.disabledFeatures,
        },
        store: this.$store,
        i18n: this.$i18n,
        router: this.$router,
        provide: providers,
      }).$mount()
      popupInstance.$on('close', () => {
        this.closeAllPopups()
        this.removeSpider()
      })
      popupInstance.$on('cancelService', productUid => {
        this.closeAllPopups()
        this.removeSpider()
        this.cancelUid = productUid
        this.showCancelPanel = true
      })
      popupInstance.$on('navigateToUid', async productUid => {
        const settings = await this.calculateSettingsForSelectedUid(productUid)
        if (settings.zoom && settings.center) {
          this.map.setCenter(settings.center)
          this.map.setZoom(settings.zoom)
        } else if (settings.bounds) {
          this.map.fitBounds(settings.bounds, { padding: 200 })
        }
        this.showPopupForUid(productUid)
      })
      popupInstance.$on('navigateToIx', async ixName => {
        await this.navigateToIx(ixName)
      })

      const popup = new mapboxGl.Popup({
        maxWidth: '70%',
        offset,
        className: 'mp-popup',
      })
        .setDOMContent(popupInstance.$el)
        .setLngLat(coordinates)
        .addTo(this.map)
      // This is needed eo ensure that the popup ends up in the right position and not off
      // the edge of the screen.
      this.$nextTick(() => {
        popup.setLngLat(coordinates)
      })

      this.animateRocket = false
      this.showingPopup = true
      popup.on('close', () => {
        this.removeSpider()
        this.showingPopup = false
        this.displayRocketIfRequired()
      })
    },
    showLocationPopup(locationId, coordinates, offset = BASE_MARKER_POPUP_OFFSET) {
      // If we are showing the click popup, then we no longer want to show the hover one
      this.hoverPopup.remove()
      this.closeAllPopups()


      // Mounting it like this means that the element is fully initialised before creating the popup, so it
      // puts it in the right place.
      const location = this.locations.find(loc => loc.id === locationId)
      const popupInstance = new LocationPopup({
        propsData: {
          location,
          disabledFeatures: this.disabledFeatures,
        },
        i18n: this.$i18n,
      }).$mount()
      popupInstance.$on('addPort', locId => {
        this.$router.push({ path: '/create-megaport/port', query: { locationId: locId } })
      })
      popupInstance.$on('addMCR', locId => {
        this.$router.push({ path: '/create-megaport/mcr', query: { locationId: locId } })
      })
      popupInstance.$on('addMVE', locId => {
        this.$router.push({ path: '/mve', query: { locationId: locId } })
      })

      const popup = new mapboxGl.Popup({
        maxWidth: '70%',
        offset,
        className: 'mp-popup',
      })
        .setDOMContent(popupInstance.$el)
        .setLngLat(coordinates)
        .addTo(this.map)

      this.animateRocket = false
      this.showingPopup = true
      popup.on('close', () => {
        this.showingPopup = false
        this.displayRocketIfRequired()
      })
    },
    handleEmptyLocationsLayerClick(e) {
      const feature = e.features[0]
      const coordinates = feature.geometry.coordinates

      this.showLocationPopup(feature.properties.locationId, coordinates)
    },
    handleEmptyLocationClusterMouseEnter(e) {
      this.map.getCanvas().style.cursor = 'pointer'
      const feature = e.features[0]
      const coordinates = feature.geometry.coordinates

      const locationString = this.$tc('general.locations-count', feature.properties.point_count, { count: feature.properties.point_count })
      const firstLine = this.$t('map.location-cluster', { locationString })

      this.hoverPopup.setOffset(20)
      this.hoverPopup.setHTML(`<p>${firstLine}</p><p><strong>${this.$t('map.click-expand')}</strong></p>`).setLngLat(coordinates).addTo(this.map)
    },
    handleEmptyLocationClusterMouseLeave() {
      // Change it back to a hand when it leaves.
      this.map.getCanvas().style.cursor = ''
      // No need to show the popup any more
      this.hoverPopup.remove()
    },
    handleEmptyLocationClusterClick(e) {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['empty-location-clusters-layer'],
      })
      const clusterId = features[0].properties.cluster_id
      this.map.getSource('empty-locations-source').getClusterExpansionZoom(
        clusterId,
        (err, zoom) => {
          if (err) return
          this.hoverPopup.remove()
          this.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: Math.min(zoom + 2, this.MAPBOX_MAX_ZOOM), // Increase this to minimise the number of clicks to get to a single one
          })
        }
      )
    },
    handleOccupiedLocationsLayerMouseEnter(e) {
      const feature = e.features[0]
      if (feature.id === this.hiddenOccupiedLocationId) {
        return
      }
      // Change the cursor to a pointer when the mouse is over the places layer.
      this.map.getCanvas().style.cursor = 'pointer'

      const coordinates = feature.geometry.coordinates.slice()
      // This will not be present for an IX
      const locationId = feature.properties.locationId
      const location = this.locations.find(loc => loc.id === locationId)

      let lagCount = 0
      let portCount = 0
      let mcrCount = 0
      let mveCount = 0
      const relevantPortLike = this.myPorts.filter(port => port.locationId === locationId)
      for (const portLike of relevantPortLike) {
        switch (portLike.productType) {
          case this.G_PRODUCT_TYPE_MEGAPORT:
            if (portLike.lagId || portLike.lagPortCount) {
              lagCount++
            } else {
              portCount++
            }
            break
          case this.G_PRODUCT_TYPE_MCR2:
            mcrCount++
            break
          case this.G_PRODUCT_TYPE_MVE:
            mveCount++
            break
        }
      }

      // There are circumstances where the location may be shown as occupied but that is just because a non-owned
      // b-end port is here. In that case we don't want to show any message about the services here.
      let productsInfo = ''
      if (relevantPortLike.length) {
        const productLines = []
        if (lagCount) {
          productLines.push(`<li>${this.$tc('general.lag-count', lagCount, { count: lagCount })}</li>`)
        }
        if (portCount) {
          productLines.push(`<li>${this.$tc('ports.ports-count', portCount, { count: portCount })}</li>`)
        }
        if (mcrCount) {
          productLines.push(`<li>${this.$tc('general.mcr-count', mcrCount, { count: mcrCount })}</li>`)
        }
        if (mveCount) {
          productLines.push(`<li>${this.$tc('general.mve-count', mveCount, { count: mveCount })}</li>`)
        }
        productsInfo = `
          <p>${this.$t('map.products-location')}</p>
          <ul>${productLines.join('')}</ul>`
      }

      // Now we want to find all our port-like objects at our location / ix and add them
      // In order to represent the non-owned ports that we are connected to, we need to go through
      // all our connections and add any ports that are at that location that we are connected to
      // which are not already represented.
      const relevantUuids = new Set()
      if (feature.properties.locationType === 'Location') {
        for (const connection of this.myConnections) {
          if (connection.productType !== this.G_PRODUCT_TYPE_VXC) {
            continue
          }
          if (connection.aEnd.locationId === locationId && !relevantPortLike.find(port => port.productUid === connection.aEnd.productUid)) {
            relevantUuids.add(connection.aEnd.productUid)
          }
          if (connection.bEnd.locationId === locationId && !relevantPortLike.find(port => port.productUid === connection.bEnd.productUid)) {
            relevantUuids.add(connection.bEnd.productUid)
          }
        }
      } else {
        // Must be an IX
        for (const connection of this.myConnections) {
          if (connection.productType !== this.G_PRODUCT_TYPE_IX) {
            continue
          }
          if (connection.networkServiceType === feature.properties.networkServiceType) {
            relevantUuids.add(connection.productUid)
          }
        }
      }
      let partnerProductsInfo = ''
      if (relevantUuids.size) {
        if (feature.properties.locationType === 'Location') {
          partnerProductsInfo = `<p>${this.$tc('map.partner-count', relevantUuids.size, { count: relevantUuids.size })}</p>`
        } else {
          partnerProductsInfo = `<p>${this.$tc('map.ix-incoming-count', relevantUuids.size, { count: relevantUuids.size })}</p>`
        }
      }

      this.hoverPopup.setOffset(BASE_MARKER_POPUP_OFFSET)
      this.hoverPopup.setHTML(`
        <p class="font-weight-400">${location?.formatted.long || feature.properties.networkServiceType}</p>
        ${productsInfo}
        ${partnerProductsInfo}
        <p><strong>${this.$t('map.click-details')}</strong></p>
      `).setLngLat(coordinates).addTo(this.map)
    },
    handleOccupiedLocationsLayerMouseLeave() {
      if (this.showingSpiderDetail) {
        return
      }
      // Change it back to a hand when it leaves.
      this.map.getCanvas().style.cursor = ''
      // No need to show the popup any more
      this.hoverPopup.remove()
    },
    spiderifyFeature(feature) {
      if (!feature) {
        return
      }

      const coordinates = feature.geometry.coordinates
      // Hide the icon we have just clicked on
      this.hiddenOccupiedLocationId = feature.id
      this.map.setFeatureState({
          source: 'occupied-locations-source',
          id: feature.id,
        }, {
          visible: false,
        })

      const legs = []
      let idCounter = 0
      const locationId = feature.properties.locationId

      legs.push({
          id: idCounter,
          type: 'Location',
          color: THIRD_PARTY_COLOR,
          locationId,
          image: 'logo',
        })
      idCounter++

      // Now we want to find all our port-like objects at our location and add them
      // In order to represent the non-owned ports that we are connected to, we need to go through
      // all our connections and add any ports that are at that location that we are connected to
      // which are not already represented.
      const relevantUuids = new Set()
      for (const connection of this.myConnections) {
        if (connection.productType !== this.G_PRODUCT_TYPE_VXC) {
          continue
        }
        if (connection.aEnd.locationId === locationId) {
          relevantUuids.add(connection.aEnd.productUid)
        }
        if (connection.bEnd.locationId === locationId) {
          relevantUuids.add(connection.bEnd.productUid)
        }
      }
      const relevantPortLike = this.myPorts
        .filter(port => port.locationId === locationId && (!port.aggregationId || port.lagPrimary))

      for (const partnerUuid of [...relevantUuids]) {
        if (!relevantPortLike.find(port => port.productUid === partnerUuid)) {
          const port = findPortOrPartnerInfo(partnerUuid)
          if (port) {
            relevantPortLike.push(port)
          }
        }
      }

      // If it's come from the partner ports it will use the "companyName" + "title" attribute rather than the "productName"
      relevantPortLike.sort((a, b) => {
        const aName = a.productName || `${a.companyName} ${a.title}`
        const bName = b.productName || `${b.companyName} ${b.title}`

        return aName.toLocaleLowerCase().localeCompare(bName.toLocaleLowerCase())
      })
      for (const portLike of relevantPortLike) {
        if (!portLike.productName || portLike.thirdParty) {
          legs.push({
            id: idCounter,
            type: FEATURE_PARTNER_PORT,
            image: 'port',
            color: THIRD_PARTY_COLOR,
            data: portLike,
          })
          idCounter++
          continue
        }

        let color = undefined
        if (portLike.lagPrimary) {
          const lagPorts = [portLike, ...(portLike._subLags || [])]
          let allUp = true
          let allDown = true
          for (const port of lagPorts) {
            switch (port.up) {
              case false:
                allUp = false
                break
              case true:
                allDown = false
                break
              default:
                allUp = false
                allDown = false
                break
            }
          }
          if (allUp) {
            color = UP_COLOR_START
          } else if (allDown) {
            color = DOWN_COLOR_START
          } else {
            color = UNKNOWN_COLOR_START
          }
        } else if (portLike.provisioningStatus !== this.G_PROVISIONING_LIVE) {
          color = DOWN_COLOR_START
        } else {
          switch (portLike.up) {
            case false:
              color = DOWN_COLOR_START
              break
            case true:
              color = UP_COLOR_START
              break
            default:
              color = UNKNOWN_COLOR_START
              break
          }
        }

        let image = ''
        switch (portLike.productType) {
          case this.G_PRODUCT_TYPE_MEGAPORT:
            if (portLike.aggregationId || portLike.lagPortCount) {
              image = 'lag'
            } else {
              image = 'port'
            }
            break
          case this.G_PRODUCT_TYPE_MCR2:
            image = 'mcr'
            break
          case this.G_PRODUCT_TYPE_MVE:
            image = 'mve'
        }

        legs.push({
            id: idCounter,
            type: portLike.productType,
            image,
            color,
            data: portLike,
          })
        idCounter++
      }
      this.spiderifier.spiderfy(coordinates, legs)
    },
    showIxPopup(networkServiceType) {
      const features = this.map.querySourceFeatures('occupied-locations-source')
      const feature = features.find(f => f.properties.networkServiceType === networkServiceType)
      const coordinates = feature.geometry.coordinates

      const popupInstance = new IXPopup({
        propsData: {
          networkServiceType,
        },
        store: this.$store,
        router: this.$router,
        i18n: this.$i18n,
      }).$mount()
      popupInstance.$on('navigateToUid', async productUid => {
        const settings = await this.calculateSettingsForSelectedUid(productUid)
        if (settings.zoom && settings.center) {
          this.map.setCenter(settings.center)
          this.map.setZoom(settings.zoom)
        } else if (settings.bounds) {
          this.map.fitBounds(settings.bounds, { padding: 200 })
        }
        this.showPopupForUid(productUid)
      })
      popupInstance.$on('navigateToIx', async ixName => {
        await this.navigateToIx(ixName)
      })

      const popup = new mapboxGl.Popup({
        offset: BASE_MARKER_POPUP_OFFSET,
        className: 'mp-popup',
      })
        .setDOMContent(popupInstance.$el)
        .setLngLat(coordinates)
        .addTo(this.map)

      this.animateRocket = false
      this.showingPopup = true
      popup.on('close', () => {
        this.showingPopup = false
        this.displayRocketIfRequired()
      })
    },
    handleOccupiedLocationsLayerClick(e) {
      if (this.showingSpiderDetail) {
        return
      }
      // If we are showing the click popup or spider, then we no longer want to show the hover popup
      this.hoverPopup.remove()
      this.closeAllPopups()

      const feature = e.features[0]

      if (feature.properties.locationType === 'Location') {
        this.spiderifyFeature(feature)
      } else if (feature.properties.locationType === 'IX') {
        this.showIxPopup(feature.properties.networkServiceType)
      }
    },
    handleOccupiedLocationClusterMouseEnter(e) {
      e.stopPropagation()
      const clusterId = parseInt(e.currentTarget.getAttribute('clusterid'))
      const feature = this.map.querySourceFeatures('occupied-locations-source', {
        filter: ['==', ['get', 'cluster_id', ['properties']], clusterId],
      })[0]
      if (!feature) {
        return
      }
      const coordinates = feature.geometry.coordinates

      this.map.getSource('occupied-locations-source').getClusterLeaves(feature.properties.cluster_id, Infinity, 0, (err, leafFeatures) => {
        if (!err) {
          let locationCount = 0
          let ixCount = 0
          for (const leafFeature of leafFeatures) {
            if (leafFeature.properties.locationType === 'Location') {
              locationCount++
            } else if (leafFeature.properties.locationType === 'IX') {
              ixCount++
            }
          }
          const locationString = this.$tc('general.locations-count', locationCount, { count: locationCount })
          const ixString = this.$tc('map.ix-count', ixCount, { count: ixCount })

          let firstLine = undefined
          if (ixCount) {
            firstLine = this.$t('map.location-ix-cluster', { locationString, ixString })
          } else {
            firstLine = this.$t('map.location-cluster', { locationString })
          }
          this.hoverPopup.setOffset(25)
          this.hoverPopup.setHTML(`<p>${firstLine}</p><p><strong>${this.$t('map.click-expand')}</strong></p>`).setLngLat(coordinates).addTo(this.map)
        }
      })
    },
    handleOccupiedLocationClusterMouseLeave() {
      // No need to show the popup any more
      this.hoverPopup.remove()
    },
    handleOccupiedLocationClusterClick(e) {
      this.closeAllPopups()

      e.stopPropagation()
      const clusterId = parseInt(e.currentTarget.getAttribute('clusterid'))
      const feature = this.map.querySourceFeatures('occupied-locations-source', {
        filter: ['==', ['get', 'cluster_id', ['properties']], clusterId],
      })[0]

      this.map.getSource('occupied-locations-source').getClusterExpansionZoom(
        clusterId,
        (err, zoom) => {
          if (err) return
          this.hoverPopup.remove()
          this.map.easeTo({
            center: feature.geometry.coordinates,
            zoom: Math.min(zoom + 2, this.MAPBOX_MAX_ZOOM), // Increase this to minimise the number of clicks to get to a single one
          })
        }
      )
    },
    handleMidpointMouseEnter(e) {
      // Change the cursor to a pointer when the mouse is over the places layer.
      this.map.getCanvas().style.cursor = 'pointer'

      const feature = e.features[0]
      const coordinates = feature.geometry.coordinates

      // Since non-primitive properties are serialised as JSON, we need to parse the JSON to get the connections data:
      const connections = JSON.parse(feature.properties.connections)

      this.hoverPopup.setOffset(CENTER_LINE_MARKER_SIZE / 2)
      this.hoverPopup.setHTML(`
        <p>${this.$tc('map.line-connections-count', connections.length, { count: connections.length })}</p>
        <p><strong>${this.$t('map.click-details')}</strong></p>
      `).setLngLat(coordinates).addTo(this.map)
    },
    handleMidpointMouseLeave() {
      // Change it back to a hand when it leaves.
      this.map.getCanvas().style.cursor = ''
      // No need to show the popup any more
      this.hoverPopup.remove()
    },
    async navigateToIx(ixName) {
      this.closeAllPopups()

      const ixCoordinates = await getIXLocationData(ixName)
      const zoom = await this.zoomLevelForNonClusteredLocation(ixName)
      this.map.flyTo({
          center: ixCoordinates,
          zoom,
          essential: true,
        })
      const listener = () => {
        this.showIxPopup(ixName)
        this.map.off('idle', listener)
      }
      this.map.on('idle', listener)
    },
    showConnectionsPopup(connections, start, end, coordinates, highlightedUuid = undefined) {
      // If we are showing the click popup, then we no longer want to show the hover one
      this.hoverPopup.remove()
      this.closeAllPopups()

      // Mounting it like this means that the element is fully initialised before creating the popup, so it
      // puts it in the right place.
      const popupInstance = new ConnectionsPopup({
        propsData: {
          connections,
          start,
          end,
          companyUid: this.companyUid,
          highlightedUuid,
        },
        store: this.$store,
        i18n: this.$i18n,
        router: this.$router,
        provide: providers,
      }).$mount()
      popupInstance.$on('gotoPortLike', async productUid => {
        this.closeAllPopups()
        this.removeSpider()

        const port = findPortOrPartnerInfo(productUid)
        const location = this.locations.find(loc => loc.id === port.locationId)
        // We want a zoom level minimum of 10 (max will be 20)
        const zoom = await this.zoomLevelForNonClusteredLocation(port.locationId)
        this.map.flyTo({
          center: [location.longitude, location.latitude],
          zoom,
          essential: true,
        })
        const listener = () => {
          // Note that this just returns features represented in the current tiles, but we have alredy flown to it by now
          const features = this.map.querySourceFeatures('occupied-locations-source')
          const feature = features.find(f => Math.abs(f.geometry.coordinates[0] - location.longitude) < EPSILON && Math.abs(f.geometry.coordinates[1] - location.latitude) < EPSILON)
          this.spiderifyFeature(feature)
          this.spiderifier.each(spiderLeg => {
            if (spiderLeg.feature.data?.productUid === productUid) {
              const popupOffsets = MapboxglSpiderifier.popupOffsetForSpiderLeg(spiderLeg)
              this.fixSpiderOffsets(popupOffsets)
              this.showPortLikePopup(port, spiderLeg.mapboxMarker.getLngLat(), popupOffsets)
            }
          })
          this.map.off('idle', listener)
        }
        this.map.on('idle', listener)
      })
      popupInstance.$on('gotoIx', async ixName => {
        await this.navigateToIx(ixName)
      })
      popupInstance.$on('cancelService', productUid => {
        this.closeAllPopups()
        this.cancelUid = productUid
        this.showCancelPanel = true
      })

      const popup = new mapboxGl.Popup({
        maxWidth: '70%',
        offset: CENTER_LINE_MARKER_SIZE / 2,
        focusAfterOpen: false,
        className: 'mp-popup',
      })
        .setDOMContent(popupInstance.$el)
        .setLngLat(coordinates)
        .addTo(this.map)
      // This is needed eo ensure that the popup ends up in the right position and not off
      // the edge of the screen.
      this.$nextTick(() => {
        popup.setLngLat(coordinates)
      })

      this.animateRocket = false
      this.showingPopup = true
      popup.on('close', () => {
        this.showingPopup = false
        this.displayRocketIfRequired()
      })
    },
    handleMidpointClick(e) {
      const feature = e.features[0]
      const coordinates = feature.geometry.coordinates
      const connections = JSON.parse(feature.properties.connections)
      const start = JSON.parse(feature.properties.start)
      const end = JSON.parse(feature.properties.end)

      this.showConnectionsPopup(connections, start, end, coordinates)
    },
    renderSearchResult(item) {
      if (item.properties?.mpType) {
        // It's one of our own internal items
        let icon = ''
        switch (item.properties.mpType) {
          case 'EmptyLocation':
            icon = '/map-images/empty-location-pin.svg'
            break
          case 'OccupiedLocation':
            icon = '/map-images/occupied-location-pin.svg'
            break
          case this.G_PRODUCT_TYPE_MEGAPORT:
            icon = this.images.port_black
            break
          case 'LAG':
            icon = this.images.lag_black
            break
          case this.G_PRODUCT_TYPE_MCR2:
            icon = this.images.mcr_black
            break
          case this.G_PRODUCT_TYPE_MVE:
            icon = this.images.mve_black
            break
          case this.G_PRODUCT_TYPE_VXC:
            icon = this.images.vxc_black
            break
          case 'TRANSIT':
            icon = this.images.transitVxc_black
            break
          case this.G_PRODUCT_TYPE_IX:
            icon = this.images.ix_black
            break
          default:
            throw new Error('Unknown type')
        }
        return `
          <div class="d-flex flex-row-centered full-width">
            <div class="mr-1 color-primary">
              <img src="${icon}" alt="${item.properties.mpType}" width="18">
            </div>
            <div class="mapboxgl-ctrl-geocoder--suggestion custom-geocoder">
              <div class="mapboxgl-ctrl-geocoder--suggestion-title" >${escape(item.properties.title)}</div>
              <div class="mapboxgl-ctrl-geocoder--suggestion-address" >${escape(item.properties.details)}</div>
            </div
          </div>
          `
      } else {
        // Use the same formatting as the default from mapbox
        const placeName = item.place_name.split(',')
        return `
          <div class="mapboxgl-ctrl-geocoder--suggestion">
            <div class="mapboxgl-ctrl-geocoder--suggestion-title">${escape(placeName[0])}</div>
            <div class="mapboxgl-ctrl-geocoder--suggestion-address">${escape(placeName.splice(1, placeName.length).join(','))}</div>
          </div>
          `
      }
    },
    renderSearchTitle(item) {
      return item.properties?.mpType ? escape(item.properties.title) : escape(item.place_name)
    },
    zoomIn(lots) {
      const zoomAmount = lots ? 3 : 1
      this.zoom = Math.min(this.zoom + zoomAmount, this.MAPBOX_MAX_ZOOM)
      this.map.setZoom(this.zoom)
    },
    zoomOut(lots) {
      const zoomAmount = lots ? 3 : 1
      this.zoom = Math.max(this.zoom - zoomAmount, this.MAPBOX_MIN_ZOOM)
      this.map.setZoom(this.zoom)
    },
    sourceDataLoaded(e) {
      if (e.isSourceLoaded && (e.sourceId === 'connections-source' || e.sourceId === 'occupied-locations-source')) {
        this.debouncedUpdateClusterMarkers()
        if (e.sourceId === 'connections-source') {
          // This is fired every time the source is loaded, so we need to make sure we only configure the layers once per change in data.
          const newSourceData = stableStringify(e.source.data)
          if (newSourceData !== this.lastConnectionsSourceData) {
            this.lastConnectionsSourceData = newSourceData
            this.configureConnectionsLayers()
          }
        }
      }
    },
    locationMapper(location) {
      return {
        id: location.id,
        type: 'Feature',
        properties: { // We want something to tell us how to draw it, something that references it, and anything we will search on
          locationType: 'Location',
          locationId: location.id,
          address: location.address,
          dc: location.dc,
          diversityZones: location.diversityZones,
          metro: location.metro,
          name: location.name,
          products: location.products,
        },
        geometry: {
          type: 'Point',
          coordinates: [location.longitude, location.latitude],
        },
      }
    },
    updateOccupiedClusterMarkers() {
      if (!this.map.isSourceLoaded('occupied-locations-source') || !this.map.isSourceLoaded('connections-source')) {
        return
      }
      for (const marker of this.clusterMarkers) {
        marker.remove()
      }
      this.clusterMarkers = []
      const features = this.map.querySourceFeatures('occupied-locations-source')
      for (const feature of features) {
        if (!feature.properties.cluster) {
          continue
        }
        // Use the cluster_id as the id for the marker
        // Iterate through the leaf nodes
        let upCount = 0
        let downCount = 0
        let unknownCount = 0
        this.map.getSource('occupied-locations-source').getClusterLeaves(feature.properties.cluster_id, Infinity, 0, (err, leafFeatures) => {
          if (!err) {
            for (const leafFeature of leafFeatures) {
              let allRelevantConnections = []
              if (leafFeature.properties.locationType === 'Location') {
                allRelevantConnections.push(...this.myConnections.filter(connection => connection.aEnd?.locationId === leafFeature.properties.locationId))
                allRelevantConnections.push(...this.myConnections.filter(connection => connection.bEnd?.locationId === leafFeature.properties.locationId))
              } else {
                // Must be an IX
                allRelevantConnections.push(...this.myConnections.filter(connection => connection.networkServiceType === leafFeature.properties.networkServiceType))
              }
              for (const connection of allRelevantConnections) {
                if (connection.provisioningStatus !== this.G_PROVISIONING_LIVE) {
                  downCount++
                } else if (connection.up === true) {
                  upCount++
                } else if (connection.up === false) {
                  downCount++
                } else {
                  unknownCount++
                }
              }

            }

            const marker = new mapboxGl.Marker({
              element: createClusterMarkerSvg(feature.properties.point_count, upCount, downCount, unknownCount),
            }).setLngLat(feature.geometry.coordinates)
            const markerElement = marker.getElement()
            markerElement.style.cursor = 'pointer'
            markerElement.setAttribute('clusterid', feature.properties.cluster_id)
            markerElement.addEventListener('mouseenter', e => this.handleOccupiedLocationClusterMouseEnter(e))
            // Make sure that no layers below get the event so they can't take over the hover popup.
            markerElement.addEventListener('mousemove', e => e.stopPropagation())
            markerElement.addEventListener('mouseleave', () => this.handleOccupiedLocationClusterMouseLeave())
            markerElement.addEventListener('click', e => this.handleOccupiedLocationClusterClick(e))

            this.clusterMarkers.push(marker)
            marker.addTo(this.map)
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~mapbox-gl/dist/mapbox-gl.css";
@import "~mapboxgl-spiderifier/index.css";
@import "~@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

::v-deep .mp-popup {
  .mapboxgl-popup-content {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 18px 12px;
    background-color: var(--mp-body-background-color);
    border-radius: 12px;
    padding: 2rem;
  }

  &.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: var(--mp-body-background-color);
  }
  &.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: var(--mp-body-background-color);
  }
  &.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: var(--mp-body-background-color);
  }
  &.mapboxgl-popup-anchor-rigth .mapboxgl-popup-tip {
    border-left-color: var(--mp-body-background-color);
  }

  .mapboxgl-popup-close-button {
    color: var(--color-text-regular);
    font-size: 2.5rem;
    top: 0.5rem;
    right: 0.5rem;
  }
}
.el-popover {
  ul {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  table {
    border-collapse: collapse;
  }
  tr td {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid var(--card-border-color);
    border-top: 1px solid var(--card-border-color);
    word-break: break-word;
    &.key {
      text-align: center;
      padding: 0.5rem;
    }
    &.value {
      padding: 0.5rem;
    }
  }
  tr th {
    padding-bottom: 7px;
  }
}

#mapbox-container {
  position: relative;
}

#mapbox {
  height: 100%;
  min-height: 500px;
}
#overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
#controls {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  i {
    color: #979797;
    font-size: 2rem;
  }

  .selected,
  .selected:focus {
    width: 100%;
    height: 100%;
    background-color: #f1f5f8;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: black;
    }
  }

  .control-group {
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px 15px;
    &.geocoder-group {
      background-color: white;
      margin-right: 0.6rem;
      border-color: var(--card-border-color);
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 1rem;
    }
  }

  .el-button {
    width: 50px;
    height: 50px;
    padding: 3px;
    border-radius: 12px;
    &:focus {
      background-color: white;
      border-color: var(--card-border-color);
    }
    &:active {
      border-color: var(--card-border-color);
    }
  }
  .stacked {
    position: relative;
    :nth-child(1) {
      position: absolute;
      top: -4px;
      left: 8px;
    }
    :nth-child(2) {
      position: absolute;
      top: -16px;
      left: 16px;
    }
  }
  .top-left {
    margin: 1rem;
    pointer-events: all;
    width: fit-content;
    display: flex;
  }

  .top-center {
    position: absolute;
    left: 50%;
    top: 5px;
  }

  .capture-events {
    pointer-events: all;
  }

  .top-right {
    margin: 1rem;
    position: absolute;
    top: 0;
    right: 0;
    width: fit-content;
    display: flex;
  }

  .top-button {
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .middle-button {
    border-top: none;
    border-bottom: none;
    border-radius: 0;
  }
  .bottom-button {
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}
.controls-icon {
  max-width: 28px;
  max-height: 28px;
}

.filtered-message {
  background-color: var(--color-warning-light-5);
  color: black;
  border-radius: var(--border-radius-base);
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  font-size: 90%;
}
::v-deep .spider-pin {
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .mapboxgl-popup-content {
  width: fit-content;
}

::v-deep .hover-popup {
  .mapboxgl-popup-content {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 18px 12px;
    border-radius: 12px;
    padding: 2rem;
  }

  p {
    font-size: 16px;
    margin: 0;
    line-height: 24px;
  }
  li {
    font-size: 14px;
    font-weight: 300;
  }
}

.key-image {
  max-height: 5rem;
  max-width: 6rem;
}

::v-deep .spider-leg-container .spider-leg-line {
  display: none;
}
</style>

<style lang="scss">
/* This doesn't work with v-deep */
#geocoder .mapboxgl-ctrl-geocoder {
  box-shadow: none;
  > svg {
    width: 30px;
    height: 30px;
    top: 4px;
    left: 0;
  }
}

#geocoder input {
  padding-left: 35px;
  border: none;
}

#geocoder .custom-geocoder {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
