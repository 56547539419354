<!-- eslint-disable vue/one-component-per-file -->
<template>
  <section>
    <template v-if="!isDistributorAccount">
      <!-- All the Map stuff -->
      <section>
        <map-view :selected-uid="mapFocusUid" />
      </section>

      <!-- All the dashboard widget stuff -->
      <section id="widgets"
        class="widgets">
        <!-- Ports -->
        <div class="widget">
          <div class="widget-header">
            <mu-mega-icon icon="MEGAPORT" />
            {{ $t('productNames.ports') }}
          </div>
          <div class="widget-body">
            <div class="status-value">
              <p>{{ portsStatuses.up }}</p>
              <p class="status"
                :style="{'color': midUp}">
                {{ $t('general.up') }}
              </p>
            </div>
            <div ref="portGraph" />
            <div class="status-value">
              <p>{{ portsStatuses.down }}</p>
              <p class="status"
                :style="{'color': midDown}">
                {{ $t('general.down') }}
              </p>
            </div>
          </div>
          <div v-if="portsStatuses.unknown"
            class="unknown-value"
            :style="{'color': midUnknown}">
            {{ portsStatuses.unknown }} {{ $t('general.unknown') }}
          </div>
        </div>

        <!-- MCRs -->
        <div class="widget">
          <div class="widget-header">
            <mu-mega-icon icon="MCR" />
            {{ $t('productNames.mcrs') }}
          </div>
          <div class="widget-body">
            <div class="status-value">
              <p>{{ mcrStatuses.up }}</p>
              <p class="status"
                :style="{'color': midUp}">
                {{ $t('general.up') }}
              </p>
            </div>
            <div ref="mcrGraph" />
            <div class="status-value">
              <p>{{ mcrStatuses.down }}</p>
              <p class="status"
                :style="{'color': midDown}">
                {{ $t('general.down') }}
              </p>
            </div>
          </div>
          <div v-if="mcrStatuses.unknown"
            class="unknown-value"
            :style="{'color': midUnknown}">
            {{ mcrStatuses.unknown }} {{ $t('general.unknown') }}
          </div>
        </div>


        <!-- MVEs -->
        <div class="widget">
          <div class="widget-header">
            <mu-mega-icon icon="MVE" />
            {{ $t('productNames.mves') }}
          </div>
          <div class="widget-body">
            <div class="status-value">
              <p>{{ mveStatuses.up }}</p>
              <p class="status"
                :style="{'color': midUp}">
                {{ $t('general.up') }}
              </p>
            </div>
            <div ref="mveGraph" />
            <div class="status-value">
              <p>{{ mveStatuses.down }}</p>
              <p class="status"
                :style="{'color': midDown}">
                {{ $t('general.down') }}
              </p>
            </div>
          </div>
          <div v-if="mveStatuses.unknown"
            class="unknown-value"
            :style="{'color': midUnknown}">
            {{ mveStatuses.unknown }} {{ $t('general.unknown') }}
          </div>
        </div>

        <!-- VXCs -->
        <div class="widget">
          <div class="widget-header">
            <mu-mega-icon icon="VXC" />
            {{ $t('productNames.vxcs') }}
          </div>
          <div class="widget-body">
            <div class="status-value">
              <p>{{ vxcStatuses.up }}</p>
              <p class="status"
                :style="{'color': midUp}">
                {{ $t('general.up') }}
              </p>
            </div>
            <div ref="vxcGraph" />
            <div class="status-value">
              <p>{{ vxcStatuses.down }}</p>
              <p class="status"
                :style="{'color': midDown}">
                {{ $t('general.down') }}
              </p>
            </div>
          </div>
          <div v-if="vxcStatuses.unknown"
            class="unknown-value"
            :style="{'color': midUnknown}">
            {{ vxcStatuses.unknown }} {{ $t('general.unknown') }}
          </div>
        </div>


        <!-- IXs -->
        <div class="widget">
          <div class="widget-header">
            <mu-mega-icon icon="IX" />
            {{ $t('productNames.ixs') }}
          </div>
          <div class="widget-body">
            <div class="status-value">
              <p>{{ ixStatuses.up }}</p>
              <p class="status"
                :style="{'color': midUp}">
                {{ $t('general.up') }}
              </p>
            </div>
            <div ref="ixGraph" />
            <div class="status-value">
              <p>{{ ixStatuses.down }}</p>
              <p class="status"
                :style="{'color': midDown}">
                {{ $t('general.down') }}
              </p>
            </div>
          </div>
          <div v-if="ixStatuses.unknown"
            class="unknown-value"
            :style="{'color': midUnknown}">
            {{ ixStatuses.unknown }} {{ $t('general.unknown') }}
          </div>
        </div>
        <h4 class="service-status">
          <a class="cursor-pointer"
            @click="$router.push('/service-status')">
            <mu-mega-icon icon="outages"
              width="22" />
            {{ $t('service-status.maintenance-outages') }}
          </a>
        </h4>
      </section>
    </template>


    <!-- All the general information tiles -->
    <div v-if="!disabledFeatures.megaportMarketing">
      <el-dialog :visible.sync="showMcrVideo"
        :center="true"
        data-name="video-popup-dialog"
        :title="$t('productNames.mcrLong')">
        <template #footer>
          <el-button @click="showMcrVideo = false">
            {{ $t('general.close') }}
          </el-button>
        </template>

        <div v-if="showMcrVideo"
          class="videoWrapper">
          <iframe :title="$t('productNames.mcrLong')"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/eEBmWrZGgHQ?autoplay=1"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen />
        </div>
      </el-dialog>

      <div v-if="services.length === 0"
        class="text-align-center pt-3">
        <el-card class="header-video-card">
          <video id="introVideo"
            width="560"
            height="315"
            controls>
            <source src="https://media.megaport.com/portal/welcome-to-megaport.mp4"
              type="video/mp4">
          </video>
        </el-card>
        <div class="p-2">
          <el-button type="primary"
            @click="$router.push('/services')">
            {{ $t('dashboard.get-started') }}
          </el-button>
          <el-button data-name="btn-start-tour"
            type="primary"
            :disabled="filteredLocations.length === 0"
            @click="startTour">
            {{ $t('tour.start-tour') }}
          </el-button>
        </div>
      </div>

      <div class="card-container p-2"
        :class="services.length !== 0 ? 'no-header' : ''">
        <!-- Getting started card -->
        <mp-card v-if="services.length > 0">
          <template #header>
            <mu-mega-icon icon="GettingStarted"
              class="card-header-image" />
          </template>
          <p>{{ $t('dashboard.navigation') }}</p>
          <template #footer>
            <el-button :disabled="filteredLocations.length === 0"
              @click="startTour">
              {{ $t('tour.start-tour') }}
            </el-button>
          </template>
        </mp-card>

        <!-- Marketplace card -->
        <mp-card>
          <template #header>
            <mu-mega-icon icon="MarketplaceLabel"
              class="card-header-image" />
          </template>
          <p>{{ $t('dashboard.marketplace-marketing') }}</p>
          <template #footer>
            <el-button data-name="btn-visit-marketplace"
              @click="visitMarketplace">
              {{ $t('dashboard.visit-marketplace') }}
            </el-button>
          </template>
        </mp-card>

        <!-- Megaport documentation card -->
        <mp-card v-if="!disabledFeatures.knowledgeBase">
          <template #header>
            <mu-mega-icon icon="Documentation"
              class="card-header-image" />
          </template>
          <p>{{ $t('dashboard.deep-dive') }}</p>
          <p class="mt-1">
            {{ $t('dashboard.quick-links') }}
          </p>
          <ul>
            <li>
              <a @click="visitDocumentation('overview/')">{{ $t('dashboard.ql-overview') }}</a>
            </li>
            <li>
              <a @click="visitDocumentation('connections/')">{{ $t('dashboard.ql-vxc') }}</a>
            </li>
            <li>
              <a @click="visitDocumentation('cloud/megaport/')">{{ $t('dashboard.ql-cloud') }}</a>
            </li>
            <li>
              <a @click="visitDocumentation('mcr/')">{{ $t('dashboard.ql-mcr') }}</a>
            </li>
          </ul>
          <template #footer>
            <el-button data-name="btn-visit-documentation"
              @click="visitDocumentation('')">
              {{ $t('dashboard.documentation') }}
            </el-button>
          </template>
        </mp-card>

        <!-- Cloud router card -->
        <mp-card>
          <template #header>
            <mu-mega-icon icon="CloudRouter"
              class="card-header-image" />
          </template>
          <img src="@/assets/images/dashboard/mcr-thumb.png"
            :alt="$t('productNames.mcrLong')"
            class="full-width cursor-pointer"
            @click="watchMcrVideo">
          <template #footer>
            <el-button data-name="btn-watch-video"
              @click="watchMcrVideo">
              {{ $t('dashboard.video') }}
            </el-button>
          </template>
        </mp-card>
      </div>
    </div>

    <transit-modal />
  </section>
</template>

<script>
// External tools
// eslint-disable vue/one-component-per-file
// import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
// Internal tools
import captureSentryError from '@/utils/CaptureSentryError.js'
import { captureEvent } from '@/utils/analyticUtils'
import { setServicesPage } from '@/utils/MapDataUtils'
import { createClusterMarkerSvg } from '@/utils/UpDownUnknown.js'
import { interpolateColors } from '@/utils/ColorUtils.js'
// Components
import MPCard from '@/components/ui-components/MPCard.vue'
import MapView from '@/components/services/MapView.vue'
import TransitModalComponent from '@/components/ui-components/TransitModal.vue'
// Globals
import {
  UP_COLOR_START,
  DOWN_COLOR_START,
  UNKNOWN_COLOR_START,
  UP_COLOR_END,
  DOWN_COLOR_END,
  UNKNOWN_COLOR_END,
} from '@/Globals'

export default {
  name: 'MpDashboard',

  components: {
    'mp-card': MPCard,
    'map-view': MapView,
    'transit-modal': TransitModalComponent,
  },

  inject: ['disabledFeatures'],

  data() {
    return {
      showMcrVideo: false,
      mapFocusUid: undefined, // TODO: Fix this
      UP_COLOR_START,
      DOWN_COLOR_START,
      UNKNOWN_COLOR_START,
    }
  },

  computed: {
    ...mapState('Services', ['services']),
    ...mapGetters('Services', ['filteredLocations', 'allMyServices']),
    ...mapGetters('Auth', ['isDistributorAccount']),
    midUp() {
      return interpolateColors(UP_COLOR_START, UP_COLOR_END, 0.5)
    },
    midDown() {
      return interpolateColors(DOWN_COLOR_START, DOWN_COLOR_END, 0.5)
    },
    midUnknown() {
      return interpolateColors(UNKNOWN_COLOR_START, UNKNOWN_COLOR_END, 0.5)
    },
    serviceStatuses() {
      const statuses = {}

      // All service types
      const serviceTypes = [
        this.G_PRODUCT_TYPE_MEGAPORT,
        this.G_PRODUCT_TYPE_MCR2,
        this.G_PRODUCT_TYPE_MVE,
        this.G_PRODUCT_TYPE_VXC,
        this.G_PRODUCT_TYPE_IX,
      ]

      // Shape the object to return
      serviceTypes.forEach(type => {
        statuses[type] = {
          up: 0,
          down: 0,
          unknown: 0,
        }
      })

      // Populate the object to return
      for (const { productType, provisioningStatus, up } of this.allMyServices) {  
        const isNotLive = provisioningStatus !== this.G_PROVISIONING_LIVE

        if (isNotLive || up === false) {
          statuses[productType].down++
        } else if (up === true) {
          statuses[productType].up++
        } else {
          statuses[productType].unknown++
        }
      }

      return statuses
    },
    portsStatuses() {
      return this.serviceStatuses[this.G_PRODUCT_TYPE_MEGAPORT]
    },
    mcrStatuses() {
      return this.serviceStatuses[this.G_PRODUCT_TYPE_MCR2]
    },
    mveStatuses() {
      return this.serviceStatuses[this.G_PRODUCT_TYPE_MVE]
    },
    vxcStatuses() {
      return this.serviceStatuses[this.G_PRODUCT_TYPE_VXC]
    },
    ixStatuses() {
      return this.serviceStatuses[this.G_PRODUCT_TYPE_IX]
    },
  },

  watch: {
    serviceStatuses() {
      this.updateGraphs()
    },
  },

  mounted() {
    setServicesPage('/dashboard')
    const video = document.getElementById('introVideo')
    if (video) {
      video.addEventListener('play', this.introVideoStartedPlaying)
      video.addEventListener('error', this.introVideoError, true)
    }
    this.$nextTick(() => { this.updateGraphs() })
  },

  beforeDestroy() {
    const video = document.getElementById('introVideo')
    if (video) {
      video.removeEventListener('play', this.introVideoStartedPlaying)
      video.removeEventListener('error', this.introVideoError, true)
    }
  },

  methods: {
    updateGraphs() {
      const options = {
        circleSize: 38,
        circleStrokeWidth: 2,
        upIndicatorWidth: 7,
        forceLine: true,
        circleFillColor: 'white',
        textColor: 'black',
        fontSize: 30,
        fontWeight: 800,
        sectorSplitWidth: 3,
      }
      try {
        const portSvg = createClusterMarkerSvg(this.portsStatuses.up + this.portsStatuses.down, this.portsStatuses.up, this.portsStatuses.down, 0, options)
        this.$refs.portGraph?.replaceChildren(portSvg)
        const mcrSvg = createClusterMarkerSvg(this.mcrStatuses.up + this.mcrStatuses.down, this.mcrStatuses.up, this.mcrStatuses.down, 0, options)
        this.$refs.mcrGraph?.replaceChildren(mcrSvg)
        const mveSvg = createClusterMarkerSvg(this.mveStatuses.up + this.mveStatuses.down, this.mveStatuses.up, this.mveStatuses.down, 0, options)
        this.$refs.mveGraph?.replaceChildren(mveSvg)
        const vxcSvg = createClusterMarkerSvg(this.vxcStatuses.up + this.vxcStatuses.down, this.vxcStatuses.up, this.vxcStatuses.down, 0, options)
        this.$refs.vxcGraph?.replaceChildren(vxcSvg)
        const ixSvg = createClusterMarkerSvg(this.ixStatuses.up + this.ixStatuses.down, this.ixStatuses.up, this.ixStatuses.down, 0, options)
        this.$refs.ixGraph?.replaceChildren(ixSvg)
      } catch (error) {
        // If the user's browser doesn't support replaceChildren, then they will still see the numbers. Any browser since early 2020 should be fine.
      }
    },
    introVideoStartedPlaying() {
      captureEvent('dashboard.intro-video.play.click')
    },
    introVideoError(event) {
      this.$notify.error({
        title: this.$t('dashboard.video-error'),
        message: this.$t('dashboard.video-error-message'),
      })

      // Hopefully this will capture the NotAllowedError which we are occasionally seeing and allow us to dig further into the issue.
      captureSentryError(event.target.error)
    },
    startTour() {
      this.$root.$emit('introStartedAtBeginning', true)
      if (!document.getElementById('intro-step-0') && document.getElementById('hamburgerTriggerButton')) {
        this.$nextTick(() => {
          document.getElementById('hamburgerTriggerButton').click()
          this.$tours['introTour'].start()
        })
      } else {
        this.$tours['introTour'].start()
      }
      captureEvent('dashboard.start-tour.click')
    },
    visitMarketplace() {
      captureEvent('dashboard.visit-marketplace.click')

      this.$router.push('/marketplace', () => {
        // empty function is intentional
      })
    },
    visitDocumentation(path) {
      captureEvent('dashboard.docs-link.click', { path })

      window.open(`https://docs.megaport.com/${path}`, '_blank')
    },
    watchMcrVideo() {
      captureEvent('dashboard.watch-mcr-video.click')

      this.showMcrVideo = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~element-ui/packages/theme-chalk/src/mixins/mixins";

.widgets {
  display: flex;
  gap: 4.4rem;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2.5rem;
  background-color: white;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 18px 12px;
  margin: 2rem;
  color: black;
  .widget {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    // min-width: 205px;
    // height: 170px;
    .widget-header {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      margin-bottom: 1rem;
      svg {
        height: 24px;
        width: 24px;
        display: block;
        margin-right: 1rem;
      }
    }
    .widget-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .status-value {
        text-align: center;
        width: 55px;
        p {
          margin: 0;
          font-family: "Mulish", sans-serif;
          font-weight: 800;
          line-height: 18px;
        }
        .status {
          text-transform: uppercase;
          letter-spacing: -0.7px;
        }
      }
    }
    .unknown-value {
      text-align: center;
    }
  }
}

.card-container {
  display: flex;
  gap: 4rem;

  & > .el-card {
    flex-basis: 100%;
    border-radius: 20px;
  }

  & > .is-always-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 18px 12px;
  }

  a {
    cursor: pointer;
  }

  p {
    margin: 0;
  }

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.full-width {
  grid-column-start: 1;
  grid-column-end: -1;
}

.header-video-card {
  margin: auto;
  max-width: 560px;
  max-height: 315px;
}

.card-header-image {
  height: 40px;
  width: auto;
  display: block;
}

.service-status {
  flex-basis: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
</style>
