<template>
  <section>
    <div>
      <!-- Connection icon and status information -->
      <lazy-popover width="300"
        placement="top"
        :open-delay="500"
        trigger="hover">
        <div class="text-align-left fs-1-4rem">
          <p class="text-align-center word-break-normal">
            <strong>{{ statusInfo.message }}</strong>
          </p>
          <template v-if="statusInfo.bgpStatuses.length">
            <p><strong>{{ $t('connections.bgp-statuses') }}</strong></p>
            <div v-for="(status, index) in statusInfo.bgpStatuses"
              :key="index"
              class="flex-row-centered position-relative">
              <div class="service-status-icon"
                :class="status.status" />
              <div class="ml-2-4">
                {{ status.message }}
              </div>
            </div>
          </template>
        </div>

        <template #reference>
          <mu-mega-icon :icon="connectionIcon"
            class="product-icon"
            :class="[whitelabelClass, statusInfo.status]" />
        </template>
      </lazy-popover>
    </div>
    <!-- Details -->
    <div class="full-width">
      <div>
        {{ connection.productName }}
        <span v-if="connection.provisioningStatus !== G_PROVISIONING_LIVE"
          class="ml-0-5">({{ snakeToCapitalizedWords(connection.provisioningStatus) }})</span>
      </div>
      <div v-if="isVxc"
        class="details-line">
        <strong>
          {{ endOfThisVxc }}
          {{ $t('general.end') }}{{ aEndOwned ? '' : ` (${$t('connections.third-party-owner')})` }}
        </strong>
        {{ $t('productNames.vxc') }} ({{ displaySpeed }})
      </div>
      <div v-else
        class="details-line">
        {{ $t('productNames.ix') }} ({{ displaySpeed }})
      </div>

      <!-- Buttons -->
      <div class="action-buttons">
        <!-- Show/edit details -->
        <el-tooltip placement="top"
          :open-delay="500">
          <template #content>
            <div class="text-align-center">
              <div>{{ $t('general.type-details', { type: $t(isIx ? 'productNames.ix' : 'productNames.vxc') }) }}</div>
              <div v-if="serviceIsShutdown">
                {{ $t('general.type-shutdown-state', { type: $t(isIx ? 'productNames.ix' : 'productNames.vxc') }) }}
              </div>
            </div>
          </template>
          <el-button name="showDetails"
            :class="{ 'is-shutdown': serviceIsShutdown }"
            data-testid="edit-vxc"
            size="mini"
            @click="showDetails()">
            <i :class="serviceIsShutdown ? 'fa' : 'fal'"
              class="fa-cog action arrow-icon"
              aria-hidden="true" />
          </el-button>
        </el-tooltip>

        <!-- Connection -->
        <el-tooltip placement="top"
          :content="connectionTooltip">
          <el-button size="mini"
            @click="navigateToUid(connection.productUid)">
            <div class="d-flex flex-align-center">
              <i class="fal fa-arrow-circle-right arrow-icon mr-1" />
              {{ $t('map.navigate-to', { serviceName: $t(isIx ? 'productNames.ix' : 'productNames.vxc') }) }}
            </div>
          </el-button>
        </el-tooltip>

        <!-- Other end -->
        <template v-if="otherEnd">
          <el-tooltip v-if="isVxc || aEndIsIX"
            placement="top"
            :content="otherEndTooltip">
            <el-button size="mini"
              @click="navigateToUid(otherEnd.productUid)">
              <div class="d-flex flex-align-center">
                <i class="fal fa-arrow-circle-right arrow-icon mr-1" />
                <template v-if="!otherEndOwned">
                  <img v-if="marketplaceProfile"
                    :src="marketplaceProfile._logo"
                    :alt="$t('images.profile-logo')"
                    class="mxlogo"
                    :title="marketplaceProfile.companyName"
                    @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">
                  <mu-mega-icon v-else
                    icon="Marketplace"
                    class="icon" />
                </template>
                <mu-mega-icon v-else
                  :icon="otherEndIcon"
                  class="icon" />
                <i v-if="otherEndDiversityZone"
                  class="far fa-dot-circle diversity-zone-icon ml-1"
                  :class="`diversity-color-${otherEndDiversityZone}`"
                  :title="$t(`services.${otherEndDiversityZone}-zone`)" />
              </div>
            </el-button>
          </el-tooltip>

          <el-tooltip v-else
            placement="top"
            :content="otherEndTooltip">
            <el-button size="mini"
              @click="navigateToIx(connection.networkServiceType)">
              <div class="d-flex flex-align-center">
                <i class="fal fa-arrow-circle-right arrow-icon mr-1" />
                {{ connection.networkServiceType }}
              </div>
            </el-button>
          </el-tooltip>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'

import LazyPopover from '@/components/ui-components/LazyPopover.vue'
import { setFallbackImage } from '@/utils/fallbackImage'

import {
  convertSpeed,
  snakeToCapitalizedWords,
  closeTooltipsAndNavigateToRoute,
} from '@/helpers.js'

import {
  findPortOrPartnerInfo,
} from '@/components/map-support/map-utilities'
import { captureEvent, productTypeToEvent } from '@/utils/analyticUtils'

export default Vue.extend({
  components: {
    LazyPopover,
  },

  props: {
    connection: {
      type: Object,
      required: true,
    },
    port: {
      type: Object,
      required: false,
      default: () => {},
    },
    aEndIsIX: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      findPort: 'Services/findPort',
      companyUid: 'Company/companyUid',
    }),
    ...mapState('Marketplace', ['marketplaceData']),
    isVxc() {
      return this.connection.productType === this.G_PRODUCT_TYPE_VXC
    },
    isIx() {
      return this.connection.productType === this.G_PRODUCT_TYPE_IX
    },
    endOfThisVxc() {
      return this.port.productUid === this.connection.bEnd.productUid ? 'B' : 'A'
    },
    aEndOwned() {
      return (this.endOfThisVxc === 'B' && this.connection.aEnd.ownerUid !== this.companyUid) ? false : true
    },
    displaySpeed() {
      return convertSpeed(this.connection.rateLimit)
    },
    serviceIsLive() {
      return this.connection.provisioningStatus === this.G_PROVISIONING_LIVE
    },
    serviceIsShutdown() {
      if (this.isVxc) {
        return this.connection.resources?.vll?.shutdown || false
      }
      return this.connection.resources?.vpls_interface?.shutdown || false
    },
    statusInfo() {
      if (this.isVxc) {
        return this.vxcStatusInfo
      }
      return this.ixStatusInfo
    },
    vxcStatusInfo() {
      const statusInfo = {
        status: '',
        message: '',
        bgpStatuses: [],
      }

      if (!this.serviceIsLive) {
        statusInfo.message = `${this.$t('services.provisioning-status')}: ${this.connection.provisioningStatus}`
      } else if (typeof this.connection.up !== 'boolean') {
        statusInfo.status = 'partial-success'
        statusInfo.message = this.$t('connections.vxc-status-unknown')
      } else {
        statusInfo.status = this.connection.up ? 'all-go' : 'all-fail'
        statusInfo.message = this.connection.up ? this.$t('connections.vxc-is-up') : this.$t('connections.vxc-is-down')

        // There is only BGP information for MCRs, and it is part of the csp_connection object.
        // Add the BGP statuses as informational content.
        let csps = []

        // The API is currently returning csp_connection sometimes as an array and sometimes as an object,
        // which seems to depend on whether a single connection exists (object) or multiple (array).
        // Because of this, we need to check what we get and if not an array,
        // push the object into an empty array so that we can handle both cases similarly.
        if (this.connection.resources?.csp_connection) {
          if (Array.isArray(this.connection.resources.csp_connection)) {
            csps = this.connection.resources.csp_connection
          } else {
            csps.push(this.connection.resources.csp_connection)
          }
        }

        for (const cspInfo of csps) {
          if (cspInfo.connectType && cspInfo.connectType === 'VROUTER' && cspInfo.bgp_status) {
            let connections = []
            if (cspInfo.interfaces && cspInfo.interfaces[0].bgpConnections) {
              connections = cspInfo.interfaces[0].bgpConnections
            }
            const keys = Object.keys(cspInfo.bgp_status)
            for (const key of keys) {
              const bgpInfo = connections.find(connection => connection.peerIpAddress === key) || {}
              const thisStatus = {}
              thisStatus.message = `${this.$t('services.ip-address')}: ${key} ${(bgpInfo.description || '')}`
              if (bgpInfo.shutdown) {
                thisStatus.message += ` (${this.$t('connections.shut-down-by-user')})`
              }

              switch (cspInfo.bgp_status[key]) {
                case 0:
                  thisStatus.status = 'all-fail'
                  break
                case 1:
                  thisStatus.status = 'all-go'
                  break
                default:
                  thisStatus.status = 'partial-success'
                  break
              }
              statusInfo.bgpStatuses.push(thisStatus)
            }
          }
        }
      }
      return statusInfo
    },
    ixStatusInfo() {
      const statusInfo = {
        status: '',
        message: '',
        bgpStatuses: [],
      }

      if (!this.serviceIsLive) {
        statusInfo.message = `${this.$t('services.provisioning-status')}: ${this.connection.provisioningStatus}`
      } else if (typeof this.connection.up !== 'boolean') {
        statusInfo.status = 'partial-success'
        statusInfo.message = this.$t('connections.ix-status-unknown')
      } else {
        statusInfo.status = this.connection.up ? 'all-go' : 'all-fail'
        statusInfo.message = this.connection.up ? this.$t('connections.ix-is-up') : this.$t('connections.ix-is-down')

        // Add the BGP statuses as informational content.
        if (this.connection.resources.bgp_connection) {
          for (const bgp of this.connection.resources.bgp_connection) {
            const thisStatus = {}

            thisStatus.message = `${this.$t('services.ip-address')}: ${bgp.isp_ip_address}`

            switch (bgp.up) {
              case 0:
                thisStatus.status = 'all-fail'
                break
              case 1:
                thisStatus.status = 'all-go'
                break
              default:
                thisStatus.status = 'partial-success'
                break
            }
            statusInfo.bgpStatuses.push(thisStatus)
          }
        }
      }
      return statusInfo
    },
    otherEnd() {
      if (this.isIx || this.aEndIsIX) {
        return this.findPort(this.connection.parentPortUid)
      }
      return this.endOfThisVxc === 'A'
        ? findPortOrPartnerInfo(this.connection.bEnd.productUid)
        : findPortOrPartnerInfo(this.connection.aEnd.productUid)
    },
    otherEndOwned() {
      return this.otherEnd.companyUid === this.companyUid
    },
    isTransitVXC() {
      return this.otherEnd?.connectType === 'TRANSIT'
    },
    connectionIcon() {
      if (this.isVxc) {
        if (this.marketplaceProfile) {
          return 'Marketplace'
        }
        if (this.isTransitVXC) {
          return 'MegaportInternet'
        }
      }
      return this.connection.productType
    },
    marketplaceProfile() {
      if (this.isIx) {
        return false
      }

      if (!this.otherEnd) return false
      if (this.otherEnd.companyUid === this.companyUid) return false
      return this.marketplaceData.find(mp => this.otherEnd.companyUid === mp.companyUid)
    },
    serviceIsInDesign() {
      return this.connection.provisioningStatus === this.G_PROVISIONING_DESIGN
    },
    whitelabelClass() {
      return this.serviceIsInDesign ? 'wl-vxc-configured-color' : 'wl-vxc-color'
    },
    connectionTooltip() {
      if (this.connection.networkServiceType) {
        return this.$t('map.navigate-to', { serviceName: this.connection.networkServiceType })
      }
      return this.$t('map.navigate-to', { serviceName: this.connection.productName })
    },
    otherEndTooltip() {
      if (this.connection.networkServiceType && !this.aEndIsIX) {
        return this.$t('map.navigate-to', { serviceName: this.connection.networkServiceType })
      }
      if (this.otherEnd.productType) {
        return this.$t('map.navigate-to', { serviceName: this.otherEnd.productName })
      }
      if (this.otherEnd.companyName || this.otherEnd.title) {
        return this.$t('map.navigate-to', { serviceName: `${this.otherEnd.companyName} ${this.otherEnd.title}` })
      }
      if (this.otherEnd.thirdParty) {
        return this.$t('map.navigate-to', { serviceName: this.otherEnd.productName })
      }
      return ''
    },
    otherEndIcon() {
      if (this.otherEnd.productType === this.G_PRODUCT_TYPE_MEGAPORT && (this.otherEnd.aggregationId || this.otherEnd.lagPortCount)) {
        return 'LAG'
      }
      // If it's a CSP then it will come back with an object that includes the connectType but not a productType
      // so we will default it to be treated like a port.
      return this.otherEnd?.productType || this.otherEnd?.properties?.locationType || 'MEGAPORT'
    },
    showOtherEndDetails() {
      if (this.otherEnd.productType) {
        // It's a port-like object
        if (this.otherEnd.provisioningStatus === this.G_PROVISIONING_DESIGN || this.isTransitVXC) {
          return false
        }
        return true
      }
      return false
    },
    otherEndDiversityZone() {
      return this.showOtherEndDetails ? this.otherEnd.config?.diversityZone || this.otherEnd.diversityZone : null
    },
  },
  methods: {
    setFallbackImage,
    snakeToCapitalizedWords,
    navigateToUid(productUid) {
      this.$emit('navigateToUid', productUid)
    },
    navigateToIx(networkServiceType) {
      this.$emit('navigateToIx', networkServiceType)
    },
    showDetails() {
      if (this.serviceIsInDesign) {
        closeTooltipsAndNavigateToRoute(this.$router, `/create-connection/${this.connection.productUid}`)
        this.trackButtonClick('edit-config')
      } else {
        closeTooltipsAndNavigateToRoute(this.$router, `/edit-connection/${this.connection.productUid}`)
        this.trackButtonClick('edit')
      }
    },
    trackButtonClick(buttonName) {
      let eventType = productTypeToEvent(this.connection.productType)
      if (this.port) {
        eventType = `${productTypeToEvent(this.port.productType)}.${eventType}`
      }
      captureEvent(`dashboard.map.${eventType}.${buttonName}.click`)
    },
  },
})
</script>

<style lang="scss" scoped>
.details-line {
  width: 100%;
  font-size: 1.3rem;
  line-height: 18px;
  color: var(--color-text-regular);
}

svg.product-icon {
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  fill: currentColor;
  line-height: 0;
  &.all-go {
    color: var(--color-success);
  }
  &.partial-success {
    color: var(--color-warning);
  }
  &.all-fail {
    color: var(--color-danger);
  }
}

.mxlogo {
  height: 3.6rem;
}
.el-button:has(.mxlogo) {
  padding-top: 0;
  padding-bottom: 0;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  .el-button + .el-button {
    margin: 0 2.5px;
  }
}

svg.icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  fill: currentColor;
  line-height: 0;
}

.diversity-zone-icon,
.arrow-icon {
  font-size: 2rem;
}
</style>
