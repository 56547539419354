<template>
  <section class="connection-item"
    :class="{ highlighted }">
    <!-- Goto start -->
    <el-tooltip v-if="startObject"
      placement="top"
      :content="startTooltip">
      <el-button size="mini"
        @click="navigateTo(startObject)">
        <div class="d-flex flex-align-center">
          <i class="fas fa-arrow-circle-left arrow-icon" />
          <template v-if="!startIsOwned">
            <img v-if="marketplaceProfile"
              :src="marketplaceProfile._logo"
              :alt="$t('images.profile-logo')"
              class="mxlogo ml-1"
              :title="marketplaceProfile.companyName"
              @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">
            <mu-mega-icon v-else-if="isIx"
              icon="ix"
              class="icon ml-1" />
            <mu-mega-icon v-else
              icon="Marketplace"
              class="icon ml-1" />
          </template>
          <mu-mega-icon v-else
            :icon="startIcon"
            class="icon ml-1" />
          <i v-if="startDiversityZone"
            class="far fa-dot-circle diversity-zone-icon ml-1"
            :class="`diversity-color-${startDiversityZone}`"
            :title="$t(`services.${startDiversityZone}-zone`)" />
        </div>
      </el-button>
    </el-tooltip>

    <!-- Connection icon and status information -->
    <lazy-popover width="300"
      placement="top"
      :open-delay="500"
      trigger="hover">
      <div class="text-align-left fs-1-4rem">
        <p class="text-align-center word-break-normal">
          <strong>{{ statusInfo.message }}</strong>
        </p>
        <template v-if="statusInfo.bgpStatuses.length">
          <p><strong>{{ $t('connections.bgp-statuses') }}</strong></p>
          <div v-for="(status, index) in statusInfo.bgpStatuses"
            :key="index"
            class="flex-row-centered position-relative">
            <div class="service-status-icon"
              :class="status.status" />
            <div class="ml-2-4">
              {{ status.message }}
            </div>
          </div>
        </template>
      </div>

      <template #reference>
        <mu-mega-icon :icon="connectionIcon"
          class="product-icon"
          :class="[whitelabelClass, statusInfo.status]" />
      </template>
    </lazy-popover>

    <!-- Main content -->
    <div class="connection-main-content">
      <!-- First line -->
      <div>
        {{ calculatedServiceName }}
        <span v-if="!serviceIsLive && serviceIsShutdown"
          class="ml-0-5">({{ snakeToCapitalizedWords(connection.provisioningStatus) }}, {{ $t('general.shut-down') }})</span>
        <span v-else-if="!serviceIsLive || serviceIsShutdown"
          class="ml-0-5">({{ !serviceIsLive ? snakeToCapitalizedWords(connection.provisioningStatus) : $t('general.shut-down') }})</span>
      </div>
      <!-- Second line -->
      <div class="service-details">
        {{ productName }} ({{ displaySpeed }})
      </div>

      <!-- Actions and notes -->
      <conflict-component :service="connection"
        class="mb-0-5" />

      <!-- Action buttons line -->
      <div class="action-block">
        <!-- Show/edit details -->
        <el-tooltip placement="top"
          :open-delay="500">
          <template #content>
            <div class="text-align-center">
              <div>{{ $t('general.type-details', { type: productName }) }}</div>
              <div v-if="serviceIsShutdown">
                {{ $t('general.type-shutdown-state', { type: productName }) }}
              </div>
            </div>
          </template>
          <span>
            <el-button name="showDetails"
              :class="{ 'is-shutdown': serviceIsShutdown }"
              data-testid="edit-vxc"
              @click="showDetails()">
              <i :class="serviceIsShutdown ? 'fa' : 'fal'"
                class="fa-cog action"
                aria-hidden="true" />
            </el-button>
          </span>
        </el-tooltip>

        <el-tooltip v-if="isIx && !serviceIsInDesign"
          placement="top"
          :content="$t('menu.ix-telemetry')"
          :open-delay="500">
          <el-button data-name="IXGraphs"
            @click="trackedNavigation('telemetry', `/tools/ix-flows/${connection.productUid}`)">
            <i class="fas fa-chart-area"
              aria-hidden="true" />
          </el-button>
        </el-tooltip>

        <!-- Delete -->
        <el-tooltip placement="top"
          :content="deleteTooltip"
          :open-delay="500">
          <div>
            <el-button data-name="deleteService"
              :disabled="!canDelete"
              @click="cancelService">
              <i class="fal fa-trash-alt action"
                aria-hidden="true" />
            </el-button>
          </div>
        </el-tooltip>

        <!-- Lock -->
        <el-tooltip v-show="!connection.locked && !connection.adminLocked && showLock"
          placement="top"
          :content="lockTooltip"
          :open-delay="500">
          <span>
            <el-button v-loading="loading"
              :disabled="!isAdmin || !serviceIsLive"
              name="lock"
              data-testid="lock-vxc"
              @click="changeLockStatus(true)">
              <i class="fal fa-unlock-alt action"
                aria-hidden="true" />
            </el-button>
          </span>
        </el-tooltip>
        <el-tooltip v-show="connection.locked || connection.adminLocked"
          placement="top"
          :content="lockTooltip"
          :open-delay="500">
          <span>
            <el-button v-loading="loading"
              :disabled="!isAdmin || !serviceIsLive || connection.adminLocked"
              name="unlock"
              data-testid="unlock-vxc"
              @click="changeLockStatus(false)">
              <i class="fal fa-lock action"
                :class="{adminLocked: connection.adminLocked}"
                aria-hidden="true" />
            </el-button>
          </span>
        </el-tooltip>

        <!-- Uuid -->
        <el-tooltip v-if="!serviceIsInDesign"
          placement="right"
          :content="$t('services.identifier-copy')"
          :open-delay="500">
          <span class="service-identifier"
            @click.stop="copyToClipboard(serviceUid)">#{{ serviceUid }}</span>
        </el-tooltip>
      </div>
    </div>

    <!-- Goto end -->
    <el-tooltip v-if="endObject"
      placement="top"
      :content="endTooltip">
      <el-button size="mini"
        @click="navigateTo(endObject)">
        <div class="d-flex flex-align-center">
          <template v-if="!endIsOwned">
            <img v-if="marketplaceProfile"
              :src="marketplaceProfile._logo"
              :alt="$t('images.profile-logo')"
              class="mxlogo mr-1"
              :title="marketplaceProfile.companyName"
              @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">
            <mu-mega-icon v-else-if="isIx"
              icon="ix"
              class="icon mr-1" />
            <mu-mega-icon v-else
              icon="Marketplace"
              class="icon mr-1" />
          </template>
          <mu-mega-icon v-else
            :icon="endIcon"
            class="icon mr-1" />
          <i v-if="endDiversityZone"
            class="far fa-dot-circle diversity-zone-icon mr-1"
            :class="`diversity-color-${endDiversityZone}`"
            :title="$t(`services.${endDiversityZone}-zone`)" />
          <i class="fas fa-arrow-circle-right arrow-icon" />
        </div>
      </el-button>
    </el-tooltip>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'

import sdk from '@megaport/api-sdk'

import LazyPopover from '@/components/ui-components/LazyPopover.vue'
import ConflictComponent from '@/components/services/Conflict.vue'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { setFallbackImage } from '@/utils/fallbackImage'

import {
  copyToClipboard,
  convertSpeed,
  snakeToCapitalizedWords,
  slug,
  closeTooltipsAndNavigateToRoute,
} from '@/helpers.js'

import {
  findPortOrPartnerInfo,
} from '@/components/map-support/map-utilities'
import { captureEvent, productTypeToEvent } from '@/utils/analyticUtils'

export default Vue.extend({
  components: {
    LazyPopover,
    ConflictComponent,
  },

  props: {
    connection: {
      type: Object,
      required: true,
    },
    highlighted: {
      type: Boolean,
      required: true,
    },
    start: {
      type: Object,
      required: true,
    },
    end: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapGetters({
      findPort: 'Services/findPort',
      companyUid: 'Company/companyUid',
      hasAuth: 'Auth/hasAuth',
      showLock: 'Services/showLock',
    }),
    ...mapState('Marketplace', ['marketplaceData']),

    isVxc() {
      return this.connection.productType === this.G_PRODUCT_TYPE_VXC
    },
    isIx() {
      return this.connection.productType === this.G_PRODUCT_TYPE_IX
    },
    productName() {
      return this.$t(this.isIx ? 'productNames.ix' : 'productNames.vxc')
    },
    startObject() {
      if (this.isVxc) {
        return this.findPortLikeInEnd(this.start, this.connection.aEnd.productUid, this.connection.aEnd.locationId) || this.findPortLikeInEnd(this.start, this.connection.bEnd.productUid, this.connection.bEnd.locationId)
      }
      // Must be an IX
      return this.findIXInEnd(this.start, this.connection.networkServiceType) || this.findPortLikeInEnd(this.start, this.connection.parentPortUid, this.connection.locationId)
    },
    endObject() {
      if (this.isVxc) {
        return this.findPortLikeInEnd(this.end, this.connection.aEnd.productUid, this.connection.aEnd.locationId) || this.findPortLikeInEnd(this.end, this.connection.bEnd.productUid, this.connection.bEnd.locationId)
      }
      // Must be an IX
      return this.findIXInEnd(this.end, this.connection.networkServiceType) || this.findPortLikeInEnd(this.end, this.connection.parentPortUid, this.connection.locationId)
    },
    startTooltip() {
      return this.tooltipForNavigation(this.startObject) || this.$t('map.navigate-to', { serviceName: this.$t('general.start') })
    },
    endTooltip() {
      return this.tooltipForNavigation(this.endObject) || this.$t('map.navigate-to', { serviceName: this.$t('general.end') })
    },
    startIcon() {
      if (this.startObject.productType === this.G_PRODUCT_TYPE_MEGAPORT && (this.startObject.aggregationId || this.startObject.lagPortCount)) {
        return 'LAG'
      }
      // If it's a CSP then it will come back with an object that includes the connectType but not a productType
      // so we will default it to be treated like a port.
      return this.startObject?.productType || this.startObject?.properties?.locationType || 'MEGAPORT'
    },
    endIcon() {
      if (this.endObject.productType === this.G_PRODUCT_TYPE_MEGAPORT && (this.endObject.aggregationId || this.endObject.lagPortCount)) {
        return 'LAG'
      }

      // If it's a CSP then it will come back with an object that includes the connectType but not a productType
      // so we will default it to be treated like a port.
      return this.endObject?.productType || this.endObject?.properties?.locationType || 'MEGAPORT'
    },
    connectionIcon() {
      if (this.isVxc) {
        if (this.marketplaceProfile) {
          return 'Marketplace'
        }
        if (this.isTransitVXC) {
          return 'MegaportInternet'
        }
      }
      return this.connection.productType
    },
    startIsOwned() {
      if (!this.startObject) {
        return false
      }
      return this.startObject.companyUid === this.companyUid
    },
    endIsOwned() {
      if (!this.endObject) {
        return false
      }
      return this.endObject.companyUid === this.companyUid
    },
    marketplaceProfile() {
      if (this.isIx) {
        return false
      }

      let nonOwnedEnd = undefined
      if (!this.startIsOwned) {
        nonOwnedEnd = this.startObject
      } else if (!this.endIsOwned) {
        nonOwnedEnd = this.endObject
      }
      if (!nonOwnedEnd) {
        return false
      }

      const mpp = this.marketplaceData.find(mp => nonOwnedEnd.companyUid === mp.companyUid)
      if (!mpp) return false
      return {
        ...mpp,
        slug: slug(mpp.companyName),
      }
    },
    serviceIsInDesign() {
      return this.connection.provisioningStatus === this.G_PROVISIONING_DESIGN
    },
    serviceIsLive() {
      return this.connection.provisioningStatus === this.G_PROVISIONING_LIVE
    },
    serviceIsShutdown() {
      if (this.isVxc) {
        return this.connection.resources?.vll?.shutdown || false
      }
      return this.connection.resources?.vpls_interface?.shutdown || false
    },
    serviceUid() {
      return this.connection.productUid.split('-')[0]
    },
    statusInfo() {
      if (this.isVxc) {
        return this.vxcStatusInfo
      }
      return this.ixStatusInfo
    },
    vxcStatusInfo() {
      const statusInfo = {
        status: '',
        message: '',
        bgpStatuses: [],
      }

      if (!this.serviceIsLive) {
        statusInfo.message = `${this.$t('services.provisioning-status')}: ${this.connection.provisioningStatus}`
      } else if (typeof this.connection.up !== 'boolean') {
        statusInfo.status = 'partial-success'
        statusInfo.message = this.$t('connections.vxc-status-unknown')
      } else {
        statusInfo.status = this.connection.up ? 'all-go' : 'all-fail'
        statusInfo.message = this.connection.up ? this.$t('connections.vxc-is-up') : this.$t('connections.vxc-is-down')

        // There is only BGP information for MCRs, and it is part of the csp_connection object.
        // Add the BGP statuses as informational content.
        let csps = []

        // The API is currently returning csp_connection sometimes as an array and sometimes as an object,
        // which seems to depend on whether a single connection exists (object) or multiple (array).
        // Because of this, we need to check what we get and if not an array,
        // push the object into an empty array so that we can handle both cases similarly.
        if (this.connection.resources?.csp_connection) {
          if (Array.isArray(this.connection.resources.csp_connection)) {
            csps = this.connection.resources.csp_connection
          } else {
            csps.push(this.connection.resources.csp_connection)
          }
        }

        for (const cspInfo of csps) {
          if (cspInfo.connectType && cspInfo.connectType === 'VROUTER' && cspInfo.bgp_status) {
            let connections = []

            if (cspInfo.interfaces && cspInfo.interfaces[0].bgpConnections) {
              connections = cspInfo.interfaces[0].bgpConnections
            }

            const keys = Object.keys(cspInfo.bgp_status)

            for (const key of keys) {
              const bgpInfo = connections.find(connection => connection.peerIpAddress === key) || {}
              const thisStatus = {}

              thisStatus.message = `${this.$t('services.ip-address')}: ${key} ${(bgpInfo.description || '')}`

              if (bgpInfo.shutdown) {
                thisStatus.message += ` (${this.$t('connections.shut-down-by-user')})`
              }

              switch (cspInfo.bgp_status[key]) {
                case 0:
                  thisStatus.status = 'all-fail'
                  break
                case 1:
                  thisStatus.status = 'all-go'
                  break
                default:
                  thisStatus.status = 'partial-success'
                  break
              }
              statusInfo.bgpStatuses.push(thisStatus)
            }
          }
        }
      }
      return statusInfo
    },
    ixStatusInfo() {
      const statusInfo = {
        status: '',
        message: '',
        bgpStatuses: [],
      }

      if (!this.serviceIsLive) {
        statusInfo.message = `${this.$t('services.provisioning-status')}: ${this.connection.provisioningStatus}`
      } else if (typeof this.connection.up !== 'boolean') {
        statusInfo.status = 'partial-success'
        statusInfo.message = this.$t('connections.ix-status-unknown')
      } else {
        statusInfo.status = this.connection.up ? 'all-go' : 'all-fail'
        statusInfo.message = this.connection.up ? this.$t('connections.ix-is-up') : this.$t('connections.ix-is-down')

        // Add the BGP statuses as informational content.
        if (this.connection.resources.bgp_connection) {
          for (const bgp of this.connection.resources.bgp_connection) {
            const thisStatus = {}

            thisStatus.message = `${this.$t('services.ip-address')}: ${bgp.isp_ip_address}`

            switch (bgp.up) {
              case 0:
                thisStatus.status = 'all-fail'
                break
              case 1:
                thisStatus.status = 'all-go'
                break
              default:
                thisStatus.status = 'partial-success'
                break
            }

            statusInfo.bgpStatuses.push(thisStatus)
          }
        }
      }

      return statusInfo
    },
    whitelabelClass() {
      return this.serviceIsInDesign ? `wl-vxc-configured-color` : `wl-vxc-color`
    },
    calculatedServiceName() {
      if (this.isVxc) {
        // If the VXC is not owned in the A end, attempt to display its Secondary Name.
        // If that fails, or if the VXC is owned in the A end, display the Product Name.
        const aEndPort = findPortOrPartnerInfo(this.connection.aEnd.productUid)
        if (!aEndPort) {
          // Could happen if the other end is no longer publicly listed
          return this.connection.productName
        }
        const aEndOwned = aEndPort.companyUid === this.companyUid

        return !aEndOwned ? (this.connection.secondaryName || this.connection.productName) : this.connection.productName
      } else {
        return this.connection.productName
      }
    },
    displaySpeed() {
      return convertSpeed(this.connection.rateLimit)
    },
    isTransitVXC() {
      if (!this.startObject || !this.endObject) {
        return false
      }
      return this.startObject.connectType === 'TRANSIT' || this.endObject.connectType === 'TRANSIT'
    },
    showStartDetails() {
      if (!this.startObject) {
        return false
      }
      if (this.startObject.productType) {
        // It's a port-like object
        if (this.startObject.provisioningStatus === this.G_PROVISIONING_DESIGN || this.isTransitVXC) {
          return false
        }
        return true
      }
      return false
    },
    showEndDetails() {
      if (!this.endObject) {
        return false
      }
      if (this.endObject.productType) {
        // It's a port-like object
        if (this.endObject.provisioningStatus === this.G_PROVISIONING_DESIGN || this.isTransitVXC) {
          return false
        }
        return true
      }
      return false
    },
    startDiversityZone() {
      return this.showStartDetails ? this.startObject.config?.diversityZone || this.startObject.diversityZone : null
    },
    endDiversityZone() {
      return this.showEndDetails ? this.endObject.config?.diversityZone || this.endObject.diversityZone : null
    },
    canDelete() {
      if (this.connection.adminLocked) return false
      if (this.connection.locked) return false
      if ([this.G_PROVISIONING_DESIGN_DEPLOY, this.G_PROVISIONING_FAILED, this.G_PROVISIONING_DECOMMISSIONED].includes(this.connection.provisioningStatus)) return false
      return this.serviceIsInDesign || this.canPlaceOrder
    },
    canPlaceOrder() {
      return this.hasAuth('place_order')
    },
    isAdmin() {
      return this.hasAuth('company_admin')
    },
    deleteTooltip() {
      if (this.connection.locked || this.connection.adminLocked) {
        return this.$t('services.type-locked', { type: this.productName })
      }
      if (this.connection.provisioningStatus === this.G_PROVISIONING_DESIGN_DEPLOY) {
        return this.$t('services.type-being-deployed', { type: this.productName })
      }
      // This can happen if a service is decommissioned afer the services have been loaded and has not yet
      // been cleaned up during periodic updates.
      if (this.connection.provisioningStatus === this.G_PROVISIONING_DECOMMISSIONED) {
        return this.$t('services.decommissioned')
      }
      if (this.canDelete || this.serviceIsInDesign || this.canPlaceOrder) {
        return this.$t('general.delete-type', { type: this.productName })
      }
      return this.$t('general.delete-permission-denied', { type: this.productName })
    },
    lockTooltip() {
      if (this.connection.adminLocked) {
        return this.$t('services.service-locked')
      }
      if (this.connection.locked) {
        if (this.isAdmin) {
          if (this.serviceIsLive) {
            return this.$t('services.type-unlock', { type: this.productName })
          } else {
            return this.$t('services.type-unlock-notlive', { type: this.productName })
          }
        } else {
          return this.$t('services.type-locked-admin', { type: this.productName })
        }
      } else if (this.isAdmin) {
        if (this.serviceIsLive) {
          return this.$t('services.type-lock', { type: this.productName })
        } else {
          return this.$t('services.type-lock-notlive', { type: this.productName })
        }
      } else {
        return this.$t('services.type-unlocked-admin', { type: this.productName })
      }
    },

  },

  methods: {
    setFallbackImage,
    copyToClipboard,
    snakeToCapitalizedWords,
    findPortLikeInEnd(startOrEnd, productUid) {
      const port = findPortOrPartnerInfo(productUid)
      if (!port) {
        return null
      }
      if (startOrEnd.properties.cluster) {
        return startOrEnd.properties.children.find(child => child.properties.locationId === port.locationId) ? port : null
      }
      return startOrEnd.properties.locationId === port.locationId ? port : null
    },
    findIXInEnd(startOrEnd, networkServiceType) {
      if (startOrEnd.properties.cluster) {
        return startOrEnd.properties.children.find(child => child.properties.networkServiceType === networkServiceType)
      }
      return startOrEnd.properties.networkServiceType === networkServiceType ? startOrEnd : null
    },
    tooltipForNavigation(details) {
      if (!details) {
        return null
      }
      if (details.properties?.networkServiceType) {
        return this.$t('map.navigate-to', { serviceName: details.properties.networkServiceType })
      }
      if (details.productType) {
        return this.$t('map.navigate-to', { serviceName: details.productName })
      }
      if (details.companyName || details.title) {
        return this.$t('map.navigate-to', { serviceName: `${details.companyName} ${details.title}` })
      }
      if (details.thirdParty) {
        return this.$t('map.navigate-to', { serviceName: details.productName })
      }
    },
    navigateTo(details) {
      if (details.productUid) {
        this.$emit('gotoPortLike', details.productUid)
      } else {
        this.$emit('gotoIx', details.properties.networkServiceType)
      }
    },
    showDetails() {
      if (this.serviceIsInDesign) {
        closeTooltipsAndNavigateToRoute(this.$router, `/create-connection/${this.connection.productUid}`)
        this.trackButtonClick('edit-config')
      } else {
        closeTooltipsAndNavigateToRoute(this.$router, `/edit-connection/${this.connection.productUid}`)
        this.trackButtonClick('edit')
      }
    },
    cancelService() {
      this.$emit('cancelService', this.connection.productUid)
      this.trackButtonClick(this.connection.provisioningStatus === this.G_PROVISIONING_DESIGN ? 'delete-config' : 'delete')
    },
    async changeLockStatus(lock) {
      this.loading = true

      const method = lock ? 'lock' : 'unlock'
      this.trackButtonClick(method)

      try {
        await sdk.instance.product(this.connection.productUid)[method]()
        const arg = { obj: this.connection, locked: lock }
        this.$store.commit('Services/setLockedState', arg)
      } catch (e) {
        captureSentryError(e)
      } finally {
        this.loading = false
      }
    },
    trackButtonClick(buttonName) {
      captureEvent(`dashboard.map.${productTypeToEvent(this.connection.productType)}.${buttonName}.click`)
    },
    trackedNavigation(buttonName, route) {
      closeTooltipsAndNavigateToRoute(this.$router, route)
      this.trackButtonClick(buttonName)
    },
  },
})
</script>

<style lang="scss" scoped>
.connection-item {
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 1rem;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: white;
  &.highlighted {
    background-color: var(--color-primary-light-9);
  }
  .connection-main-content {
    flex-grow: 1;
  }
}

.mxlogo {
  height: 4rem;
}
.el-button:has(.mxlogo) {
  padding-top: 0;
  padding-bottom: 0;
}

svg.icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  fill: currentColor;
  line-height: 0;
}

svg.product-icon {
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  fill: currentColor;
  line-height: 0;
  &.all-go {
    color: var(--color-success);
  }
  &.partial-success {
    color: var(--color-warning);
  }
  &.all-fail {
    color: var(--color-danger);
  }
}
.arrow-icon,
.diversity-zone-icon {
  font-size: 2rem;
}

.action-block {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  span {
    align-self: center;
  }
  button.el-button {
    padding: 0;
    width: 44px;
    height: 40px;
  }
}
</style>
